.message {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
}

.success {
  background-color: #eaf0eb;
  color: #0c0c0c;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

.error {
  background-color: "errorMessage.length > 0 ? " #f8d7da " : " "";
  color: #721c24;
}

.icon {
  margin-right: 0.5rem;
}
