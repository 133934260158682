/** @format */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@font-face {
  font-family: 'RobotoRegular';
  src: local('Roboto'), local('Roboto-Regular'), url('./font/Roboto-Regular.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('./font/Roboto-Bold.woff') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoThin';
  src: local('Roboto Thin'), local('Roboto-Thin'), url('./font/Roboto-Thin.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLight';
  src: local('Roboto Light'), local('Roboto-Light'), url('./font/Roboto-Light.woff') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMedium';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('./font/Roboto-Medium.woff') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@media screen and (min-width: 600px) {
  div.small {
    display: none;
  }
}

body {
  font-family: 'RobotoLight';
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.snackbar_bar_postition {
  position: fixed;
  top: 100px;
  z-index: 9999;
}

.commonDisplayStyle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.commonDisplayStyle_1 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row;
}

.commonDisplayStyle_2 {
  display: flex !important;
  justify-content: center !important;
  align-items: start !important;
  flex-direction: column;
}

.primary_icons_dimension {
  width: 44px !important;
  height: 44px !important;
}

.primary-icon {
  width: 44px;
  height: 44px;
}

.alert-main-icon {
  width: 44px;
  height: 44px;
  align-self: center;
  margin: 16px 0 35px !important;
}

.alertImageMargin {
  margin: 0px auto !important;
}

.toggle_icon {
  width: 1.3em;
  height: 1.3em;
}

.primary_icons_dimension_1 {
  width: 44px !important;
  height: 44px !important;
}

.primary_icons_dimension_1_disabled {
  width: 44px !important;
  height: 44px !important;
  opacity: 40% !important;
  cursor: not-allowed !important;
}

.primary_icons_dimension_db_tools {
  width: 44px !important;
  height: 44px !important;
}

.primary_icons_dimension_db_tools_img {
  width: 38px !important;
  height: 38px !important;
}

.primary_icons_dimension_login {
  width: 44px !important;
  height: 44px !important;
}

.primary_icons_dimension_login:hover {
  border: 2px solid #333333 !important;
}

.dbtools_icon {
  color: #ffffff !important;
}

.primary_icons_dimension_disable {
  width: 44px !important;
  height: 44px !important;
  opacity: 40% !important;
  cursor: not-allowed !important;
}

.primary_icons_dimension_db_tools:hover {
  border: 2px solid #333333 !important;
  border-radius: 50% !important;
}

.secondary-icon {
  width: 29px;
  height: 29px;
}

.accordion-icon {
  width: 1.8125rem;
  height: 1.8125rem;
  margin-right: 0.5rem;
  margin-left: 1.125rem;
  cursor: pointer;
}

.tertiary-icon {
  width: 1.109375rem;
  height: 1.109375rem;
  cursor: pointer;
}

.drawer-accordion-summary {
  justify-content: center;
  margin: 0;
}

.drawer_secondary_icons_dimension {
  width: 29px !important;
  height: 29px !important;
}

.secondary_icons_dimension_disable {
  width: 29px !important;
  height: 29px !important;
  opacity: 40% !important;
}

.secondary-icon:hover {
  border: .3rem solid #008593 !important;
  border-radius: 50% !important;
}

.mail_primary_icons_dimensions {
  width: 78px !important;
  height: 78px !important;
}

.logout_icon_dimension {
  width: 46px !important;
  height: 46px !important;
}

.header-user-icon {
  width: 1.8125rem;
  height: 1.8125rem;
}

.footer_button_dimension {
  width: 80px !important;
  height: 50px !important;
}

.footer_icon_dimension {
  width: 46px !important;
  height: 46px !important;
}

.mail_small_size {
  font-size: 12px !important;
  color: black !important;
}

.mail_Big_size {
  font-size: 21px !important;
  color: #008593;
}

.drawer_cancel_cursor {
  cursor: pointer;
}

.misc-icon {
  width: 26px !important;
  height: 26px !important;
  cursor: pointer !important;
}

.drawer_gray_button_span {
  padding-left: 5px;
  position: relative;
  width: 100%;
  display: flex;
  color: #ffffff;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
}

.globalDrawerText:hover {
  text-decoration: underline !important;
}

.hovered {
  border: 2px solid #333333;
  border-radius: 50%;
}

.p {
  width: 90%;
  margin-top: -5;
  padding-left: 20px;
  padding-right: 10px;
  font-family: 'RobotoLight';
  font-size: 15px;
  line-height: 1.8;
  color: #2d3030;
  text-align: left;
}

.add_specific_fontcolor {
  color: #ffffff;
}

h1 {
  font-family: 'RobotoLight' !important;
  font-weight: 800;
  letter-spacing: 5px;
  font-size: 250% !important;
  text-transform: 'capitalize';
}

h2 {
  font-family: 'RobotoLight';
  color: #008593;
  font-weight: 100;
  font-size: 150%;
  margin: 0 !important;
  text-transform: 'capitalize';
}

h3 {
  font-family: 'RobotoLight' !important;
  font-weight: 100;
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-transform: capitalize;
}

Divider {
  margin-bottom: 30px !important;
}

.globalTextDecoration:hover {
  text-decoration: underline !important;
  color: #0ca8b0 !important;
}

.vertical-line {
  border-left: 1px solid black;
  height: 100%;
  margin-left: 10px;
  /* Adjust as needed */
}

.dummy_icons_styles {
  width: 44px !important;
  height: 44px !important;
}

.alertTextAlign {
  text-align: center;
}

.alertTextAlign1 {
  text-align: center !important;
}

.alertCancel {
  width: 295px !important;
  height: 10% !important;
  display: flex !important;
  justify-content: end !important;
}

.alertCancel_1 {
  width: 275px !important;
  height: 10% !important;
  display: flex !important;
  justify-content: end !important;
}

.alertCancel_2 {
  width: 295px !important;
  height: 28px !important;
  display: flex !important;
  justify-content: end !important;
}

.alertMain {
  width: 295px !important;
  text-align: center !important;
  height: 90% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.alertMainNew {
  width: 295px !important;
  height: 90% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.alertMain_1 {
  width: 275px !important;
  text-align: center !important;
  height: 90% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.alertMain_2_1 {
  width: 275px !important;
  text-align: left !important;
  height: 90% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.alertMain_2_2 {
  width: 295px !important;
  text-align: left !important;
  height: 90% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.alertMain_2_3 {
  width: 295px !important;
  text-align: left !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.alertMain_2_4 {
  width: 295px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.mainAlert {
  padding: 6px 6px !important;
}

.confirmIconStyle {
  padding-top: 37px !important;
}

.confirmIconStyle1 {
  margin-top: 38px !important;
}

.alertLeftPadding {
  font-size: 20px !important;
  font-family: 'RobotoRegular' !important;
}

.alertSpacing {
  padding-top: 20px !important;
}

.alertSpacing1 {
  padding-bottom: 16px !important;
}

.globalPencilICon {
  color: #008593 !important;
  height: 20px !important;
  cursor: pointer !important;
}

.appointmentS_margin_left {
  margin-left: 8px !important;
}

.globalIconMainBox {
  display: flex !important;
  align-items: center !important;
}

.globalMarginLeftAddWindow {
  margin-left: 8px !important;
}

.globalMarginLeftAddWindow_1 {
  margin-left: 10px !important;
}

.iconButtonGlobal {
  width: 44px !important;
  height: 44px !important;
  background-color: #00b6be !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.globalText {
  font-family: 'RobotoLight' !important;
  color: #008593 !important;
  font-size: 18px !important;
}

.dbtools_fab {
  background-color: #00b5bd !important;
  color: #ffffff !important;
}

.dbtools_fab:hover {
  border: 2px solid black;
}

.drawerGlobalText {
  font-family: 'RobotoLight !important';
  color: #ffffff !important;
  font-size: 18px !important;
  margin-left: 12px !important;
}

.drawerGlobalTextDisable {
  font-family: 'RobotoLight !important';
  color: #ffffff !important;
  font-size: 18px !important;
  margin-left: 12px !important;
  opacity: 40% !important;
}

.globalTextForDrawer {
  font-family: 'RobotoLight !important';
  color: white !important;
  font-size: 18px !important;
  margin-left: 12px !important;
  margin-top: 5px !important;
}

.globalBadgeContent {
  text-align: center;
  position: absolute !important;
  margin-top: -24px;
  margin-left: 26px;
  font-size: 24px !important;
  font-weight: bolder;
  border-color: #333333 !important;
  border: 1px solid #333333 !important;
  color: #333333 !important;
  background-color: #f4d036 !important;
  width: 16px !important;
  height: 16px !important;
}

.globalBadgeContentDisable {
  text-align: center;
  position: absolute !important;
  margin-top: -24px;
  margin-left: 26px;
  font-size: 24px !important;
  font-weight: bolder;
  border-color: #333333 !important;
  border: 1px solid #333333 !important;
  color: #333333 !important;
  background-color: #f4d036 !important;
  width: 16px !important;
  height: 16px !important;
  opacity: 40% !important;
  cursor: not-allowed !important;
}

.globalBadgeContentForStepper {
  text-align: center;
  position: relative !important;
  margin-top: 0px;
  margin-left: -25px;
  font-size: 24px !important;
  font-weight: bolder;
  border-color: #333333 !important;
  border: 1px solid #333333 !important;
  color: #333333 !important;
  background-color: #f4d036 !important;
  width: 16px !important;
  height: 16px !important;
}

.globalBadgeContentForStepper2 {
  text-align: center;
  position: relative !important;
  margin-top: 0px;
  margin-left: -30px;
  margin-right: 15px;
  font-size: 24px !important;
  font-weight: bolder;
  border-color: #333333 !important;
  border: 1px solid #333333 !important;
  color: #333333 !important;
  background-color: #f4d036 !important;
  width: 16px !important;
  height: 16px !important;
}

.globalAddContent {
  font-size: 16px !important;
}

.globalAddContent_1 {
  font-size: 12px !important;
}

.stepperMainTextSize {
  font-size: 32px !important;
  color: #333333 !important;
  font-size: 'RobotoRegular !important';
}

.ProposalstepperMainTextSize {
  font-size: 32px !important;
  color: #333333 !important;
  font-size: 'RobotoRegular !important';
}

.CoveringstepperMainTextSize {
  font-size: 32px !important;
  color: #333333 !important;
  font-size: 'RobotoRegular !important';
}

.stepperSubStack {
  display: flex !important;
}

.stepperMainBox {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.stepperMainText {
  width: 130px !important;
  margin: auto !important;
}

.stepperDisable {
  opacity: 40% !important;
}

.stepperImageMargins {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.stepperSubTextStyle {
  font-size: 18px !important;
  text-align: start !important;
  margin-left: 10px !important;
}

.ProposalstepperSubTextStyle {
  font-size: 18px !important;
  text-align: start !important;
  margin-left: 10px !important;
}

.CoveringstepperSubTextStyle {
  font-size: 18px !important;
  text-align: start !important;
  margin-left: 10px !important;
}

.stepperSubTextStyle_1 {
  font-size: 14px !important;
  text-align: start !important;
  margin-left: 10px !important;
}

.ProposalStepperStack {
  width: 99% !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
}

.CoveringStepperStack {
  width: 99% !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
}

.proposalStepperMainBox {
  margin-left: 15px;
}

.stepper_prompt_margin_bottom {
  margin-bottom: 30px;
}

.removeProposalWindowPromptMainBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.removeProposalWindowPromptMainBox_1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButtonGlobalDisabled {
  width: 44px !important;
  height: 44px !important;
  background-color: rgb(172, 224, 225) !important;
  border-radius: 50% !important;
  color: #fff !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.globalTextDisabled {
  font-family: 'RobotoLight !important';

  color: #008593 !important;
  font-size: 18px !important;
  margin-left: 12px !important;
  cursor: not-allowed;
  opacity: 40% !important;
}

.iconButtonimgGlobal {
  height: 44px !important;
}

.globalCalenderimg {
  height: 30px !important;
}

.globalUserimg {
  height: 24px !important;
}

.globalUserimg_1 {
  height: 26px !important;
}

.global_login_img {
  height: 38px !important;
  color: white !important;
}

.globalAddWindowSetimg {
  height: 28px !important;
}

.globalCreateProposalimg {
  height: 22px !important;
}

.globalContinueimg {
  height: 38px !important;
}

.globalAddITemsimg {
  height: 20px !important;
}

.globalCancelImg {
  height: 20px !important;
}

.globalSaveimg {
  height: 16px !important;
}

.globalDeleteimg {
  height: 10px !important;
}

.globalCancelimg {
  height: 21px !important;
}

.globalApplyimg {
  height: 22px !important;
  color: black !important;
}

.iconSecondaryButtonGlobal {
  width: 29px !important;
  height: 29px !important;
  background-color: #dcddde !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.iconSecondaryButtonGlobalForDrawer {
  width: 29px !important;
  height: 29px !important;
  background-color: white !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.iconSecondaryButtonGlobalDisabled {
  width: 29px !important;
  height: 29px !important;
  background-color: #dcddde !important;
  border-radius: 50% !important;
  color: #fff !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.padding_bottom_class {
  margin-bottom: 90px !important;
}

.padding_bottom_class_1 {
  padding-bottom: 90px !important;
}

.margin_bottom_class {
  margin-bottom: 30px !important;
}

title {
  font-size: 150% !important;
  font-family: 'RobotoLight';
  font-size: 50% !important;
}

code {
  font-size: 120%;
  font-family: 'RobotoLight';
  font-weight: 400;
}

.appointment_cancel_icon_margin {
  margin-left: 18px !important;
}

.calender_header {
  position: absolute;
  text-transform: uppercase;
  top: 155px;
  /* left: 200px; */
  left: 200px;
}

.greyout {
  border: 2px solid #7e7e7e;
}

.greyout_1 {
  border: 2px solid #7e7e7e;
  margin-top: 5px !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.center {
  text-align: center !important;
}

.sideDrawer {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #e2e2e3;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.App {
  text-align: center;
}

.common_display_flex_css {
  display: flex !important;
  align-items: center;
}

.common_line_height_css {
  line-height: 15px !important;
}

.common_line_height_css_1 {
  line-height: 25px !important;
}

.common_text_height_css {
  margin-top: 5px !important;
}

.icons1 {
  transform: scale(1.5);
  stroke: #000;
  stroke-width: 0.1;
}

.icon-size {
  width: 20px;
  height: 20px;
  position: absolute;
}

.icon-size-updated {
  width: 20px;
  height: 20px;
  margin-right: 1em;
}

.icons {
  transform: scale(1.8);
  box-shadow: none !important;
}

.icons:hover {
  background-color: none;
  background: 'transparent';
}

.cancel_icon {
  height: 40px;
}

.remeasure_error_msg {
  color: #aa0d0d !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  width: 100% !important;
}

.emailicons-disabled {
  transform: scale(1.5);
  stroke: rgba(61, 58, 58, 0.103);
  stroke-width: 1;
  z-index: 0;
}

.emailicons {
  transform: scale(1.5);
  stroke: #fff;
  stroke-width: 0.5;
}

.bpicons {
  transform: scale(1.8);
  stroke: #fff;
  stroke-width: 1;
}

.icons-disabled {
  transform: scale(1.8);
  stroke: rgba(61, 58, 58, 0.103);
  stroke-width: 1;
  z-index: 0;
}

.icons-disabled2 {
  transform: scale(1.8);
  stroke: rgba(61, 58, 58, 0.103);
  stroke-width: 1;
  z-index: 0;
}

.Quick {
  color: #b53030;
}

.Blinds {
  color: #38ae85;
}

.SoftShades {
  color: #38ae85;
}

.RollerShades {
  color: #38ae85;
}

.Shutters {
  color: #38ae85;
}

.cursor {
  cursor: pointer !important;
}

.edit_drawer_save_button {
  font-family: RobotoLight !important;
}

.back-button {
  color: #000;
  font-family: RobotoLight;
  background-color: #d3d5d6;
  transform: scale(0.5);
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1px !important;
  margin-bottom: -5px !important;
  margin-left: 50% !important;
  box-shadow: none !important;
}

.back-button_1 {
  color: #000;
  font-family: RobotoLight;
  background-color: #d3d5d6;
  transform: scale(0.5);
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1px !important;
  margin-bottom: -5px !important;
  margin-left: 50% !important;
  box-shadow: none !important;
}

.back-button_2 {
  color: #000;
  font-family: RobotoLight;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1px !important;
  margin-bottom: -5px !important;
  margin-left: 50% !important;
  box-shadow: none !important;
}

.back-button :active {
  background-color: #d3d5d6 !important;
}

Button {
  background-color: rgb(232, 232, 232);
}

Button:active {
  background-color: rgb(232, 232, 232) !important;
}

Button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

Button:hover {
  background-color: '#008593 !important';
}

.font_style_of_menuitem {
  font-family: 'Roboto';
}

.applybutton_span {
  float: left !important;
  width: 100%;
  display: flex;
  color: #f4f4f4;
  font-weight: normal;
  font-size: 18px;
  cursor: pointer;
}

.applybutton_span_disabled {
  float: left !important;
  width: 100%;
  display: flex;
  color: #f4f4f4;
  font-weight: normal;
  font-size: 18px;
  cursor: not-allowed;
}

.cancel_icon_style_1 {
  width: 13px;
  height: 13px;
  margin-left: 6px;
  margin-top: -1px;
}

.cancel_icon_style_2 {
  width: 13px;
  height: 13px;
  margin-left: 1px;
  margin-top: 5px;
}

.cancel_icon_style_3 {
  width: 13px;
  height: 13px;
  margin-left: 0px;
  margin-top: -1px;
}

.cancel_icon_style_4 {
  width: 13px;
  height: 13px;
  margin-left: 6px;
  margin-top: 5px;
}

.cancel_icon_style_5 {
  width: 13px;
  height: 13px;
  margin-left: 0px;
  margin-top: 5px;
}

.button-box {
  padding-top: 20px !important;
  padding-bottom: 120px;
}

.button-box-1 {
  padding-top: 20px !important;
}

.heading-box {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.heading-box-1 {
  padding-top: 20px;
}

.filter-box {
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-box-ipad {
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-result-box {
  color: #8f8f8f;
  font-size: 20px !important;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0px;
}

.prompt-box {
  background-color: #008593;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.prompt-box_1 {
  background-color: #008593;
  /* margin-bottom: 20px; */
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.prompt-box_2 {
  background-color: #008593;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.prompt-box_3 {
  background-color: #008593;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.box-margin-top {
  margin-top: 15px;
}

.warning-box {
  padding-top: 10px;
  padding-bottom: 5px;
}

.table-box {
  width: 100%;
  padding-top: 5px;
}

.table-box-1 {
  margin: 0 auto;
}

.search-table-box {
  width: auto !important;
  padding-top: 5px;
}

.pageNotFoundPageMainBox {
  height: 120px;
}

.pageNotFoundPageLogoIcon {
  width: 70%;
}

.pageNotFoundText404 {
  font-size: 220px !important;
  line-height: 1.3;
}

.pageNotFoundText {
  font-size: 36px !important;
}

.pageNotFoundImage {
  /* font-size: 36 !important; */
  width: 200px;
}

.pageNotFoundPageButton {
  background-color: #008593 !important;
  color: white !important;
}

.pageNotFoundPageButton:hover {
  background-color: #008593 !important;
}

.table-headerid {
  width: 15px !important;

  padding: 0px !important;
  padding-left: 10px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerp {
  width: 18% !important;

  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;

  color: #000;
  font-size: 16px !important;
}

.table-dimension {
  width: 100% !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.windowset-table-dimension {
  width: 50% !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.table-dimensionewsd {
  width: 550px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.table-dimensioneswp {
  width: 1150px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headereswp {
  width: 22% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-dimensionepws {
  width: 550px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.table-dimension-apply-coverings {
  width: 1050px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.proposal-select-window-table {
  width: 100% !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.finalize-salesorder-table {
  width: 100% !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.sales-order-adding-windows-table {
  width: 100% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.sales_order_accordian {
  text-align: start !important;
  padding-left: 30px !important;
  margin: 0% !important;
  width: 50% !important;
}

.sales_order_Window_name {
  width: 89% !important;
  margin-top: 10px !important;
  text-align: start !important;
  margin-left: 3px !important;
}

.sales_order_Window_name_Mainbox {
  display: flex !important;
  width: 50% !important;
  margin-top: 5px !important;
}

.sales_order_Conform_for_sales_order {
  display: flex !important;
  text-align: center !important;
  padding-left: 10px !important;
  color: #0ca8b0 !important;
  margin-top: 10px !important;
  font-size: 18px !important;
}

.table-dimension-cd-windowsets {
  width: 500px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.table-dimension-cd-proposal {
  width: 500px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header-cd-proposal0 {
  width: 15% !important;
  white-space: nowrap;

  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header-cd-proposal1 {
  width: 20% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header-cd-proposal2 {
  width: 10% !important;
  white-space: nowrap;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header-cd-proposal3 {
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header_material {
  width: 55% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table_header_cov_sys {
  width: 45% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerwsid {
  width: 12px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerwsname {
  width: 12px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  white-space: nowrap;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerwsname1 {
  width: 12px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  white-space: nowrap;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerwsidwithcheckbox {
  width: 15px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  padding-left: 37px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerwsidwithcheckbox_2 {
  width: 15px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerwsidwithcheckbox_1 {
  width: 5px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  padding-left: 10px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header {
  width: 38% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header0 {
  width: 30% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header1 {
  width: 15% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header2 {
  width: 25% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header3 {
  width: 15% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header4 {
  width: 15% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-header5 {
  width: 25% !important;
  /* width: 1150px !important; */
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerp {
  width: 15% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerfsw {
  width: 22% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerppsw {
  width: 10% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  white-space: nowrap;
}

.table-headerppsw1 {
  width: 15% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerewsd {
  width: 35% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerewsd_size {
  width: 350px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerewsd1 {
  width: 80px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpsw {
  width: 25% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headeresw_price {
  width: 8% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headeresw_price2 {
  width: 8% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headeresw_price_3 {
  width: 12% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headeresw_size {
  width: 18% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headeresw_material {
  width: 8% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerdsw_price {
  width: 8% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerdsw_size {
  width: 18% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerdsw_material {
  width: 25% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headersw1 {
  width: 8% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpsw-size {
  width: 10% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headersw2 {
  width: 18% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headersw3 {
  width: 20px !important;

  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.proposal_table_table_header {
  width: 1350px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpd_price {
  width: 8% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpd_price2 {
  width: 5% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpd_price3 {
  width: 5% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpd_size {
  width: 10%;
  padding: 0px;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 30px;
  color: #000;
  font-size: 16px;
  white-space: nowrap;
}

.table-headerpd_material {
  width: 10% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.table-headerpd2 {
  width: 10% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

/* proposal details page propsal table  */
.proposal_details_page_proposal_table {
  width: 170px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.table_left {
  padding: 0px !important;
  margin: 0px !important;
  width: 100px !important;
}

/* proposal details page propsal table  */

.proposal_table_table_header_cell {
  width: 200px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
}

.padding-windowname {
  padding-top: 8px !important;
  padding-bottom: 2px !important;
  padding-left: 14px !important;
}

.table-content-second {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 60% !important;
}

.table-content-second1 {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 80% !important;
}

.table-content-second2 {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 40% !important;
  white-space: nowrap;
}

.table-content-secondforred {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 10% !important;
}

.table-content-after-material {
  font-family: 'RobotoLight' !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 7% !important;
  white-space: nowrap;
}

.table-content-last-col {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 50% !important;
}

.table-content-secondsw {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 5px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 60% !important;
}

.small_buttons {
  background-color: #f4f4f4 !important;
  color: #000 !important;
  border-radius: 25px !important;
  margin-left: 4% !important;
  margin-bottom: 5% !important;
}

.table_padding_bottom {
  padding-bottom: 20px !important;
}

.padding_top {
  padding-top: 15px;
}

.delete_proposal_details_delete_bottom {
  margin-bottom: 10px !important;
}

.alertCantinueButton {
  background-color: white !important;
  width: 44px !important;
  height: 44px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.alertCantinueIcon {
  color: #008593 !important;
  width: 30px !important;
  height: 30px !important;
}

.proposal_table {
  width: 100% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 12px !important;
}

@media (min-width: 1200px) {
  .table-dimension {
    width: 50% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }

  .windowset-table-dimension {
    width: 50% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimensionepws {
    width: 100% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimensionewsd {
    width: 100% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-cd-proposal {
    width: 50% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-cd-proposal1 {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-cd-sales-order {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .search-table-dimension {
    width: 60% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-apply-coverings {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .proposal-select-window-table {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-header {
    width: 30% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    color: #000;
    font-size: 16px !important;
  }

  .proposal_table_table_header {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    padding-right: 30px !important;
    color: #000;
    font-size: 16px !important;
  }

  .proposal_table_table_header_cell {
    width: 2650px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    padding-right: 5px !important;
    color: #000;
    font-size: 16px !important;
  }

  .break {
    max-width: 150px !important;
    min-width: 150px !important;
    word-wrap: break-word !important;
  }

  .proposal-select-window-table {
    width: 70% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .sales-order-adding-windows-table {
    width: 1500px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .table-dimension {
    width: 1550px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .windowset-table-dimension {
    width: 50% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimensionepws {
    width: 100% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimensionewsd {
    width: 100% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-header {
    width: 30% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    color: #000;
    font-size: 16px !important;
  }

  .break {
    max-width: 150px !important;
    min-width: 150px !important;
    word-wrap: break-word !important;
  }

  .proposal-select-window-table {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .sales-order-adding-windows-table {
    width: 1650px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .previewProposalPdf {
    width: 625px !important;
    margin-left: -15%;
  }

  .remeasure_error_msg {
    width: 50% !important;
  }

  .table-dimension {
    width: 100% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .windowset-table-dimension {
    width: 50% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-header {
    width: 30% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    color: #000;
    font-size: 16px !important;
  }

  .break {
    max-width: 150px !important;
    min-width: 150px !important;
    word-wrap: break-word !important;
  }

  .search-table-box {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .proposal-select-window-table {
    width: 80% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .sales-order-adding-windows-table {
    width: 1700px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

.table-contentid {
  width: 5px !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentidforred {
  width: 2px !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentw {
  width: 10% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentwforred {
  width: 10px !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentepws {
  width: 38% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentwsid {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;

  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentwsid2 {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  padding-right: 30px !important;
}

.table-contentwsidwithcheckbox {
  width: 8% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;

  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentwsidwithcheckbox_1 {
  width: 6% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  padding-left: 8px !important;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentwsidwithcheckbox_2 {
  width: 8% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-contentwswn {
  width: 25% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-content {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-content-apply-covering {
  width: 100% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-content-apply-coveringo {
  width: 5% !important;
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-content-material {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  display: flex;
  width: 800px;
  color: #000;
  font-size: 5px !important;
  border-bottom: 0px !important;
}

.link {
  font-family: 'RobotoLight';
  font-size: 16px;
  color: #008593;
  font-weight: 400;
  text-decoration: inherit;
  padding-left: 2.5px;
}

.link2 {
  font-size: 100% !important;
  color: #008593 !important;
  font-weight: bold !important;
  text-decoration: inherit !important;
}

.link3 {
  font-family: 'RobotoLight';
  font-size: 16px;
  color: #008593;
  font-weight: 400;
  text-decoration: inherit;
  padding-left: 2.5px;
  white-space: nowrap;
}

.red-link {
  font-family: 'RobotoLight';
  font-size: 16px;
  color: red;
  font-weight: 400;
  text-decoration: inherit;
  padding-left: 5px;
}

.link-error {
  font-family: 'RobotoLight';
  font-size: 16px;
  color: red;
  font-weight: 400;
  text-decoration: inherit;
  padding-left: 2.5px;
}

.List.ListItem {
  font-family: 'RobotoLight';
}

#demo-simple-select-label {
  font-size: 125% !important;
  font-family: 'RobotoLight';
}

#outlined-size-small {
  font-family: 'RobotoLight';
}

#myDIV {
  height: 140px;
  padding: 5px;
}

.short_textfield {
  margin-left: 20% !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 80%;
}

.short_textfield_additional {
  margin-left: 20% !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 80%;
}

.email_short_textfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 80%;
}

.Addbutton {
  margin-left: 20% !important;
  width: 80%;
}

#shdwDIV {
  margin: auto;
  margin-top: 2px;
  width: 250px;
  padding: 0.01px;
  padding-top: 10px;
  background-color: rgb(233, 233, 233);
  box-shadow: 5px 5px 5px 2px rgb(115, 115, 115);
  font-size: 17px;
  font-family: Nunito;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type='text'] {
  height: 20px;
}

input[type='password'] {
  height: 20px;
}

input[type='number'] {
  height: 20px;
}

textarea {
  font-size: 15px;
  font-family: 'RobotoLight';
  padding: 15px;
  height: 30px;

  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

select {
  background-color: #f4f4f4;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 10px !important;
}

#outlined-select-option {
  height: 10px !important;
}

.formcontrol {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: white;
}

.formcontrol2 {
  z-index: 2 !important;
  position: relative !important;
}

.typography {
  font-family: 'RobotoLight';
}

.ele_inline {
  display: inline;
  background-color: 'rgb(12,168,176) !important';
  transform: 'scale(0.8)';
}

/*  button style starts*/
.button_span {
  float: left !important;
  width: 100%;
  display: flex;
  color: #0ca8b0;
  font-weight: normal;
  font-size: 18px;
  cursor: pointer;
}

.button_span_notes {
  float: left !important;
  width: 100%;
  display: flex;
  color: rgb(83, 83, 83);
  font-weight: normal;
  font-size: 18px;
  cursor: pointer;
}

.button_bottom_space {
  margin-bottom: 20px !important;
}

.button_bottom_space_salesorderaddingwindow {
  margin-bottom: 30px !important;
}

.button_top_space {
  margin-top: 20px !important;
}

.button_top_space_1_1 {
  margin-top: 25px !important;
}

.top_bottom_space {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.button_margin_left {
  margin-left: 10px !important;
}

.padding_Continue_Button_Proposal_Apply_Covering_Page {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  line-height: 20px !important;
}

.padding_Continue_Button_Proposal_Apply_Covering_Page_1 {
  margin-bottom: 20px !important;
  line-height: 20px !important;
}

.padding_Continue_Button_Proposal_Apply_Covering_Page_2 {
  line-height: 20px !important;
}

.create_button_span {
  float: left !important;
  width: 100%;
  display: flex;
  color: #0ca8b0;
  font-weight: normal;
  font-size: 18px;
  margin-top: -30px;
  cursor: pointer;
}

.button_span_disabled {
  width: 100%;
  display: flex;
  color: #a8d1d6 !important;
  font-weight: normal !important;
  font-size: 18px !important;
  cursor: not-allowed !important;
}

.button_span_notes_disabled {
  width: 100%;
  display: flex;
  color: rgb(202, 201, 201);
  font-weight: normal;
  font-size: 18px;
  cursor: not-allowed;
}

.mlr {
  margin-top: 30px;
}

.button_span:hover {
  text-decoration: underline;
}

.button_span:hover .fab_button {
  text-decoration: underline;
  color: #fff;
  background-color: #00b5bd !important;
  border: 1px solid #000;
}

.smallfab_button {
  color: #fff !important;
  background-color: rgb(12, 168, 176) !important;
  transform: scale(0.7);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  box-shadow: none !important;
  z-index: 0 !important;
}

.fab_button {
  color: #fff !important;
  background-color: #00b5bd !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  box-shadow: none !important;
  z-index: 0 !important;
}

.fab_button_notes {
  color: #fff !important;
  background-color: rgb(202, 201, 201) !important;
  transform: scale(0.6);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  box-shadow: none !important;
  z-index: 0 !important;
}

.Save_Button {
  color: #fff !important;
  background-color: rgb(255, 255, 255) !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 0 !important;
  box-shadow: 0 0 5px;
  border: 1px solid whitesmoke;
}

.save_button {
  background-color: #fff !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  box-shadow: 0 0 3px !important;
  z-index: 0 !important;
  border: 1px solid #f4f4f4 !important;
}

.fab_button_appt {
  color: #fff !important;
  background-color: gray !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  box-shadow: none !important;
  z-index: 0 !important;
}

.pdf_fab_button {
  color: #fff !important;
  background-color: rgb(12, 168, 176) !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  box-shadow: none !important;
  z-index: 0 !important;
  width: 80px !important;
  height: 80px !important;
}

.fab_whitebutton {
  color: #fff !important;
  background-color: #f4f4f4 !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
}

.fab_whitebutton_disabled {
  color: #fff !important;
  background-color: #fff !important;
  opacity: 0.3;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
  cursor: not-allowed !important;
}

.fab_button_disabled {
  color: #fff !important;
  background-color: rgb(172, 224, 225) !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
  cursor: not-allowed;
  opacity: 0.5;
}

.fab_button_notes_disabled {
  color: #fff !important;
  background-color: rgb(202, 201, 201) !important;
  transform: scale(0.6);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  z-index: 1;
  cursor: not-allowed;
  opacity: 0.5;
}

.fab_button_disabled_1 {
  color: #fff !important;
  background-color: rgb(172, 224, 225) !important;
  transform: scale(0.5);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
  cursor: not-allowed;
  opacity: 0.5;
}

.fab_button_Logout {
  color: #fff !important;
  background-color: #f4f4f4 !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
}

.fab_button_Saveicon {
  color: #fff !important;
  background-color: #f4f4f4 !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
}

.pdf_fab_button_disabled {
  color: #fff !important;
  background-color: rgb(172, 224, 225) !important;
  transform: scale(0.8);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  z-index: 1;
  cursor: not-allowed;
  opacity: 0.5;
  width: 80px !important;
  height: 80px !important;
}

.fab_button:hover {
  color: #fff;
  background-color: #00b5bd !important;
  border: 1px solid #000;
}

.button_text {
  padding-left: 5px;
  padding-top: 15px;
}

.button_text_1 {
  padding-left: 5px;
  padding-top: 1%;
  text-align: left;
}

.button_text_Return {
  padding-left: 5px;
  padding-top: 16px;
  font-size: 17px;
}

.button_text_proposal {
  padding-left: 0px;
  padding-top: 15px;
}

.proposalbutton {
  padding-top: 15px;
}

.confirm-checkbox {
  margin-left: 12px !important;
  font-size: 15px;
}

.smallbutton_text {
  padding-left: 5px;
  color: #000;
  font-size: 10px;
  padding-top: 50;
  line-height: 1;
}

.largebutton_text {
  color: #008593;
  font-size: 15px;
  padding-top: 15;
}

.button_text:hover {
  text-decoration: underline;
}

.button_text_disabled_duplicate {
  padding-left: 15px;
  padding-top: 0px;
  cursor: not-allowed;
}

.button_text_disabled {
  padding-left: 5px;
  padding-top: 15px;
  cursor: not-allowed;
}

.button_text_disabled_1 {
  padding-left: 5px;
  cursor: not-allowed;
}

.button_text_disabled_2 {
  padding-left: 5px;
  padding-top: 15px;
  cursor: not-allowed;
  opacity: 0.3;
}

.gray_button_span {
  padding-left: 5px;
  position: relative;
  width: 100%;
  display: flex;
  color: #0ca8b0;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
}

.gray_button_span_delete {
  padding-left: 5px;
  position: relative;
  width: 100%;
  display: flex;
  color: #0ca8b0;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
}

.gray_button_padding {
  padding-left: 25px;
}

.gray_button_span_disabled {
  padding-left: 5px;
  position: relative;
  width: 100%;
  display: flex;
  color: #a8d9e7;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
}

.white_button_span {
  padding-left: 5px;
  position: relative;
  width: 100%;
  display: flex;
  color: #f2f2f2;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
}

.gray_button_span:hover {
  text-decoration: underline;
}

.proposal_select_window_paddting_bottom {
  padding-bottom: 30px !important;
}

.proposal_select_window_continue_paddting_bottom {
  /* padding-bottom: 20px;
   */
  margin-bottom: 20px !important;
}

.graybutton {
  color: #000;
  font-family: 'RobotoLight';
  background-color: #d3d5d6;
  transform: scale(0.5);
  box-shadow: none !important;
  margin-left: 30px !important;
}

.graybutton_add_color {
  color: #000;
  font-family: 'RobotoLight';
  background-color: #d3d5d6;
  transform: scale(0.5);
  box-shadow: none !important;
}

.install_addtional_price_Add_button {
  color: #000;
  font-family: 'RobotoLight';
  background-color: #d3d5d6;
  transform: scale(0.5);
  box-shadow: none !important;
}

.box_outside_icons {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  background-color: #d3d5d6 !important;
}

.Save_changes_cancel_button {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  background-color: #f4fbff !important;
}

.box_outside_icons_disabled {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  background-color: rgb(172, 224, 225) !important;
}

.new_box_icon {
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  background-color: #0ca8b0 !important;
}

.icons_styles {
  width: 13px;
  height: 13px;
  position: absolute;
  margin-left: 6px;
  margin-top: 5px;
}

.new_icons_styles {
  width: 23px;
  height: 18px;
  position: absolute;
  margin-left: 10px;
  margin-top: 14px;
}

.graybutton_disabled {
  color: #000;
  font-family: 'RobotoLight';
  background-color: #d3d5d6;
  transform: scale(0.5);
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.gray_button_text_disabled {
  padding-top: 16px;
  cursor: not-allowed !important;
}

.gray_button_text_disabled_1 {
  cursor: not-allowed !important;
}

.graybutton:hover {
  border: 1px solid #000;
}

.new_gray_button_text {
  padding-top: 8px !important;
  padding-left: 12px !important;
  font-size: 18px !important;
}

.gray_button_text {
  padding-top: 16px;
}

.gray_button_text_1 {
  padding-top: 1px;
  padding-left: 14px !important;
}

.gray_button_text_4 {
  padding-top: 1px;
  padding-left: 12px !important;
}

.gray_button_text_2 {
  padding-top: 7px !important;
}

.gray_button_text_3 {
  padding-left: 13px !important;
  padding-top: 1px !important;
}

.gray_button_text:hover {
  text-decoration: underline;
}

.gray_button_span:hover .graybutton {
  text-decoration: underline;
  border: 1px solid #000;
  background-color: #d3d5d6;
}

.delete_proposal_details_delete_bottom {
  margin-bottom: 10px !important;
}

/*button style close */

/*Icons*/
.badgecontent {
  text-align: center;
  position: relative;
  margin-top: -40px;
  margin-left: 40px;
  font-size: 24px;
  font-weight: bolder;
  border-color: #000 !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  background-color: #fac41e !important;
  width: 20px !important;
  height: 20px !important;
}

.new_badgecontent {
  text-align: center;
  position: relative;
  margin-top: -2px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: bolder;
  border-color: #000 !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  background-color: #fac41e !important;
  width: 18px !important;
  height: 18px !important;
}

.pdf_badgecontent {
  text-align: center;
  position: relative;
  margin-top: -40px;
  margin-left: 40px;
  font-size: 30px;
  font-weight: bold !important;
  border-color: #000 !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  background-color: #fac41e !important;
  width: 30px !important;
  height: 30px !important;
}

.pdf_fab_span {
  border: 1px solid gray;
  background-color: #fac41e;
  height: 30px;
  text-align: center;
  margin-top: 10px;
  width: 100px;
  color: gray;
  margin-left: 6px;
  text-decoration: none !important;
}

.badgeicondisabled {
  text-align: center;
  position: absolute;
  margin-top: -40px !important;
  margin-left: 40px !important;
  font-size: 24px;
  font-weight: bolder;
  border-color: #a3a3a3 !important;
  border: 1px solid #a3a3a3 !important;
  color: #a3a3a3 !important;
  background-color: #ffe6b0 !important;
  width: 20px !important;
  height: 20px !important;
}

/*IconImage*/
.iconstyle {
  width: 3px !important;
  height: 3px !important;
  position: absolute !important;
}

.Error {
  background-color: #aa0d0d !important;
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  padding-top: 5%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  width: 310px;
  min-height: 200px;
}

.Error1 {
  background-color: #aa0d0d !important;
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  padding-top: 5%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  width: 310px;
}

.Warn {
  background-color: hsla(45, 95.7%, 54.9%, 0.95) !important;
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 310px;
  min-height: 200px;
  padding-top: 40px !important;
}

.Warn_1 {
  background-color: hsla(45, 95.7%, 54.9%, 0.95) !important;
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 310px;
  min-height: 200px;
  padding-top: 10px !important;
}

.Warn_2 {
  background-color: hsla(45, 95.7%, 54.9%, 0.95) !important;
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 310px;
  padding-top: 10px !important;
}

.Success {
  background-color: #008593 !important;

  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 310px;
  min-height: 200px;
}

.Success1 {
  background-color: #008593 !important;

  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 325px;
  min-height: 200px;
}

.Success2 {
  background-color: #008593 !important;

  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 325px;
}

.alertMsgCancelICon_1 {
  margin-right: -10px !important;
}

.Success_1 {
  background-color: #008593 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5% !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-top: 5%;
  width: 310px;
  min-height: 200px;
}

.Errorpd {
  background-color: #aa0d0d !important;
  display: flex;
  justify-content: center;
  margin-top: 120px !important;
  width: 310px;
  min-height: 200px;
}

.Messagespd {
  position: absolute;
  margin-top: 120px;
  margin-right: 10%;
  top: 0px;
  right: 0px;
}

.Errorpdforquick {
  background-color: #aa0d0d !important;
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  padding-top: 5% !important;
  width: 310px;
  min-height: 200px;
}

.SuccessEnqueue {
  background-color: hsla(159, 98.7%, 30.4%, 0.95) !important;
  display: flex;
  justify-content: center;
}

.Messages {
  position: fixed;
  margin-top: 6%;
  margin-right: -10%;
  top: 0px;
  right: 0px;
}

.cspMeasurementAlerts {
  display: flex;
  margin-top: 10px;
}

.cspMeasurementAlertsText {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #aa0d0d !important;
  margin-left: 5px !important;
}

.cspMeasurementAlertsImg {
  width: 20px;
  height: 20px;
}

.loading-text {
  padding-left: 100%;
  color: #0ca8b0;
  align-content: center;
  text-align: center;
}

.sticky-columnws-1 {
  left: 0;
  -webkit-left: 0 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  background-color: white;
  z-index: 12 !important;
  margin-right: 0% !important;
}

.sticky-columnws-1_1 {
  left: 0;
  position: sticky !important;
  background-color: white;
  z-index: 12;
}

.sticky-columnws-2 {
  left: 0;
  position: sticky;
  background-color: white;
}

.sticky-columnws-3 {
  left: 0;
  position: sticky;
  background-color: white;
  padding-left: 15px !important;
}

.sticky-column-1 {
  left: 0;
  position: sticky;
  background-color: white;
  z-index: 10;
  margin-left: 10% !important;
  margin-right: 8% !important;
}

.sticky-column-2 {
  left: 0;
  position: sticky;
  background-color: white;
  padding-left: 35px !important;
}

.sticky-column {
  left: 0;
  position: sticky;
  background-color: white;
}

.sticky-column-1forred {
  left: 0;
  position: sticky;
  background-color: white;
  z-index: 10;
}

.sticky-column-2forred {
  left: 0;
  position: sticky;
  background-color: white;
  padding-left: 35px !important;
}

.subAccessoriesAndServices {
  font-family: 'RobotoLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 20px !important;
  white-space: break-spaces;
  padding-top: 2px;
}

.headingText {
  font-size: 11;
  font-weight: bolder;
  color: #a8a8a8;
  padding-top: 1px;
}

.rowText {
  font-family: 'RobotoLight';
  font-weight: bold;
  font-size: '13px';
  color: #a5a5a5;
}

.proposal_section {
  padding: 10;
}

.boldheadingText {
  font-family: 'RobotoLight';
  font-weight: bold;
  font-size: 16px;
  color: gray;
}

.rowTextforproposal {
  font-family: 'RobotoLight';
  font-weight: normal;
  font-size: 16px;
  color: #747474;
  text-align: right;
}

.rowTotalforproposal {
  font-family: 'RobotoLight';
  font-weight: normal;
  font-size: 16px;
  color: #747474;
}

.rowTotalforproposalforAccandserv {
  font-family: 'RobotoLight';
  font-weight: normal;
  font-size: 16px;
  color: #747474;
  text-align: right;
  padding-left: 20px !important;
}

.price {
  float: right;
}

.section_row_1st {
  flex-direction: 'row';
  padding-top: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.color_white {
  color: white !important;
}

.justifycontent_sp {
  display: flex !important;
  justify-content: space-between !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* landing page style start  */

.landing_page_main_box {
  padding-top: 10px;
  margin-bottom: 10px;
}

.landing_page_sub_box {
  color: #666666 !important;
  background-color: #ffffff !important;
  border-radius: 25px !important;
  padding-top: 1 !important;
  padding-bottom: 1 !important;
  margin-top: 25px !important;
  width: 300px;
}

.landing_page_sub_box:hover {
  background-color: #ffffff;
}

/* landing page style end */

.editing_proposal_text_selectfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 100% !important;
  color: #000;
  font-weight: bold !important;
}

.text_selectfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 50% !important;
  color: #000;
  font-weight: bold !important;
}

.filtertext_selectfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 100% !important;
  color: #000;
  font-weight: bold !important;
}

.text_selectfield_upper {
  width: 50% !important;
  color: #000;
  font-weight: bold !important;
}

.margin_top {
  margin-top: 5px !important;
}

/* setApplyDefaultOptions */
.margin_bottom {
  margin-bottom: 5px !important;
}

.margin_bottom_for_options {
  margin-bottom: 10px !important;
}

.page_margin_bottom {
  padding-bottom: 30px !important;
}

.text_selectfield_copy {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 50% !important;
  color: #000;
  font-weight: bold !important;
}

.text_selectfield_copy_upper {
  width: 50% !important;
  color: #000;
  font-weight: bold !important;
}

.editing_proposal_text_inputfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 100% !important;
}

.text_inputfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 50% !important;
}

.filtertext_inputfield {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 100% !important;
}

.text_inputfield_pdf {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 80% !important;
}

.text_inputfieldforpdf {
  background-color: #f4f4f4;
  width: 76% !important;
  padding: 16px 14px 16px 14px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  margin: 10px 0px 10px 0px !important;
  height: 20px;
  font-family: 'Robotolight !important';
  font-size: 20px !important;
}

.drawer_textfield {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  width: 100%;
}

.drawer_textfield_1 {
  margin-top: 10px !important;
  width: 100%;
}

.drawer_price_total {
  width: 100%;
}

.text_selectfield_2 {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 90% !important;
  margin-left: 10% !important;
}

.text_selectfield_3 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  width: 90% !important;
  margin-left: 10% !important;
}

.clear_button {
  width: 150px;
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 45px !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.edit_drawer_button {
  width: 120px;
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  background-color: #fff !important;
  border-radius: 45px !important;
  font-size: 100% !important;
  font-weight: 100 !important;
  display: flex !important;
  justify-content: space-between !important;
}

.edit_drawer_save_button {
  width: 120px;
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  background-color: #fff !important;
  border-radius: 45px !important;
  font-size: 100% !important;
  font-weight: 100 !important;
}

.edit_drawer_button_Disabled {
  width: 130px;
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  background-color: #fff !important;
  border-radius: 45px !important;
  font-size: 100% !important;
  font-weight: 100 !important;
  display: flex !important;
  justify-content: space-around !important;
  opacity: 0.7;
  cursor: no-drop;
}

.edit_drawer_button:hover {
  background-color: #fff;
}

.clickable_header {
  cursor: pointer;
  color: #008593 !important;
  margin-top: 10px !important;
  font-family: 'RobotoLight' !important;
}

.clickable_header_1 {
  cursor: pointer;
  color: #008593 !important;
  font-family: 'RobotoLight' !important;
}

.header_top_margin {
  color: #3594a0 !important;
  margin-top: 15px !important;
  font-family: 'RobotoLight' !important;
  padding-top: 12px !important;
}

.customer_details {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 0% !important;
}

.customer_details_margin {
  width: 100% !important;
}

.customer_id {
  font-size: 105% !important;
  font-family: RobotoLight !important;
  font-weight: 100% !important;
  line-height: 1 !important;
  width: 110% !important;
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}

.customer_id_1 {
  font-size: 105% !important;
  font-family: RobotoLight !important;
  font-weight: 100% !important;
  line-height: 1 !important;
  width: 110% !important;
}

.customer_address {
  font-size: 18px !important;
  font-family: RobotoLight !important;
  font-weight: 100% !important;
  line-height: 1 !important;
  width: 110% !important;
}

.customer_Phone {
  font-size: 18px !important;
  font-family: RobotoLight !important;
  width: 100% !important;
}

.Change_address_Button {
  font-size: 100% !important;
  font-family: RobotoLight !important;
  font-weight: 100% !important;
  width: 100% !important;
}

.customer_details_icons_address {
  color: #008593 !important;
}

.customer_address_email {
  font-size: 18px !important;
  font-family: RobotoLight !important;

  width: 100% !important;
}

.customer_address_Change {
  font-size: 90% !important;
  font-family: RobotoLight !important;
  font-weight: 100% !important;
  line-height: 1 !important;
  width: 100% !important;
  margin-top: 2% !important;
  margin-bottom: 2% !important;
  color: #008593 !important;
}

.customer_address_Billing_Address {
  font-size: 90% !important;
  font-family: RobotoLight !important;
  font-weight: 100% !important;
}

.Customer_Address_stack {
  display: flex;
  flex-direction: column;
}

.customer_details_icons_Phone {
  color: #008593 !important;
  padding: 0% !important;
  position: relative;
}

.customer_details_icons {
  color: #008593 !important;
  padding: 0% !important;
}

.customer_details_icons_Email {
  color: #008593 !important;
  padding: 0% !important;
}

.customer_email {
  color: #2596be !important;
  font-family: RobotoLight !important;
  line-height: 1 !important;
}

.customer_details_segment_size {
  font-size: 105% !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.sales_order_Details_Text_Style {
  font-size: 105% !important;
}

.sales_order_Details_Text_Style_font {
  font-weight: 500 !important;
}

.mt {
  margin-top: 0% !important;
}

.my {
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.customer_details_iconbutton {
  color: #333333 !important;
  padding: 0 !important;
}

.stack_padding_bottom {
  padding-bottom: 0px !important;
}

.customer_details_paper {
  padding: 0% !important;

  box-shadow: none !important;
}

.customer_details_paper_Email {
  box-shadow: none !important;
  display: flex;
}

.customer_appointments_list {
  padding: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 20px !important;
  box-shadow: none !important;
}

.list {
  border: 1px solid #e6e6e8 !important;
  margin-bottom: 5% !important;
  border-radius: 5px !important;
  font-size: 100% !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.apply_covering_box {
  flex-grow: 1 !important;
  padding: 3 !important;
  width: 100% !important;
}

/* Customer Type media query */
@media (min-width: 1200px) {
  .centered_box {
    width: 600px !important;
  }

  .link2 {
    font-size: 18px !important;
  }

  .customer_details {
    margin-top: 1% !important;
  }

  .customer_details_margin {
    margin-top: 0% !important;
  }

  .ADD_CUSTOMER_TEXTFIELD {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    margin-top: 10px !important;
    width: 50%;
  }

  .ADD_CUSTOMER_TEXTFIELD_1 {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    width: 50%;
  }

  .paragraph {
    width: 50% !important;
  }

  .customer_address {
    font-size: 18px !important;
    font-family: RobotoLight !important;
  }

  .drawer_input_buttons_row {
    justify-content: center;
    padding-bottom: 20px;
  }

  .mainDrawerDiv {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .sideDrawer {
    padding-left: 60px !important;
    padding-right: 120px !important;
  }

  .drawer_input_result_textfield {
    width: 30% !important;
    background-color: #fff;
    font-size: '40px !important';
    border-radius: 5px;
  }

  .drawer_input_width_height_stack {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: white;
    width: 30% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  x .applybutton_span {
    width: 280px;
    display: flex;
    color: #f4f4f4;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
  }

  .applybutton_span_disabled {
    float: left !important;
    width: 100%;
    display: flex;
    color: #f4f4f4;
    font-weight: normal;
    font-size: 18px;
    cursor: not-allowed;
  }

  .customer_Phone {
    font-size: 18px !important;
    font-family: RobotoLight !important;
    width: 100% !important;
    font-size: 18px !important;
  }

  .Change_address_Button {
    font-size: 20px !important;
    margin-top: -15px !important;
  }

  .customer_id {
    font-size: 20px !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }

  .customer_address_Change {
    font-size: 90% !important;
    font-family: RobotoLight !important;
    font-weight: 100% !important;

    width: 110% !important;

    color: #008593 !important;
  }

  .select_Track_Proposal_Apply_Covering_Page {
    min-width: 200px !important;
    width: auto !important;
    margin-left: 15px;
  }

  .show_Sku_Table_Body_Cell_Proposal_Apply_Covering_Page {
    border-bottom: 0px !important;
  }

  .show_Sku_Table_Cell_Proposal_Apply_Covering_Page {
    padding: 0 !important;
  }

  .apply_final_measure_box_cancel {
    position: relative;
    padding-left: 180px !important;
  }

  .drawer_input_result_textfield {
    width: 55% !important;
    background-color: #fff;
    font-size: '40px !important';
    border-radius: 5px;
  }

  .measurement_fraction_main {
    display: flex;
    justify-content: center;
  }

  .Measurement_fraction_buttons_set1 {
    margin-right: 60px;
  }
}

@media (min-width: 1500px) and (max-width: 2000px) {
  .back-button {
    color: #000;
    font-family: RobotoLight;
    background-color: #d3d5d6;
    transform: scale(0.5);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 5px !important;
    margin-bottom: 1px !important;
    margin-left: 240% !important;
    box-shadow: none !important;
  }

  .back-button :active {
    background-color: #d3d5d6 !important;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .notes_textfield {
    width: 50% !important;
  }

  .drawer_textfield {
    width: 50% !important;
  }

  .drawer_textfield_1 {
    width: 50% !important;
  }

  .back-button {
    color: #000;
    font-family: RobotoLight;
    background-color: #d3d5d6;
    transform: scale(0.5);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 5px !important;
    margin-bottom: -5px !important;
    margin-left: 180% !important;
    box-shadow: none !important;
  }

  .back-button :active {
    background-color: #d3d5d6 !important;
  }
}

@media (min-width: 1200px) and (max-width: 1920px) {
  .notes_textfield {
    width: 50% !important;
  }

  .drawer_textfield {
    width: 50% !important;
  }

  .drawer_textfield_1 {
    width: 50% !important;
  }

  .short_textfield {
    width: 30% !important;
  }

  .short_textfield_additional {
    margin-left: 20% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 80%;
  }

  .email_short_textfield {
    width: 50% !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .sideDrawer {
    padding-left: 60px !important;
    padding-right: 120px !important;
  }

  .notes_textfield {
    width: 50% !important;
  }

  .drawer_textfield {
    width: 50% !important;
  }

  .drawer_textfield_1 {
    width: 50% !important;
  }

  .short_textfield {
    width: 30% !important;
  }

  .short_textfield_additional {
    margin-left: 20% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 80%;
    /* width: 80%; */
  }

  .email_short_textfield {
    width: 50% !important;
  }

  .paragraph {
    width: 50% !important;
  }

  .default_field_paragraph {
    width: 50%;
  }

  .back-button {
    color: #000;
    font-family: RobotoLight;
    background-color: #d3d5d6;
    transform: scale(0.5);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 5px !important;
    margin-bottom: -4px !important;
    margin-left: 140% !important;
    box-shadow: none !important;
  }

  .back-button :active {
    background-color: #d3d5d6 !important;
  }

  .centered_box {
    margin-top: 50px !important;
    width: 400px !important;
  }

  .link2 {
    font-size: 18px !important;
  }

  .customer_address {
    font-size: 18px !important;
    font-family: RobotoLight !important;

    margin-bottom: 5px !important;
  }

  .Change_address_Button {
    font-size: 18px !important;
  }

  .customer_id {
    font-size: 18px !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }

  .select_Track_Proposal_Apply_Covering_Page {
    min-width: 200px;
    width: auto;
    margin-left: 15px;
  }

  .show_Sku_Table_Body_Cell_Proposal_Apply_Covering_Page {
    border-bottom: 0px !important;
  }

  .show_Sku_Table_Cell_Proposal_Apply_Covering_Page {
    padding: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .short_textfield {
    width: 30% !important;
  }

  .short_textfield_additional {
    margin-left: 20% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 80%;
    /* width: 80%; */
  }

  .email_short_textfield {
    width: 50% !important;
  }

  .paragraph {
    width: 50% !important;
  }

  .default_field_paragraph {
    width: 50%;
  }

  .back-button {
    color: #000;
    font-family: 'RobotoLight';
    background-color: #d3d5d6;
    transform: scale(0.5);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1px;
    margin-bottom: -5px;
    margin-left: 110% !important;
    box-shadow: none !important;
  }

  .back-button :active {
    background-color: #d3d5d6 !important;
  }

  .centered_box {
    margin-top: 30px !important;
    width: 450px !important;
  }

  .link2 {
    font-size: 18px !important;
  }

  .customer_address {
    font-size: 16px !important;
    margin-bottom: 5px !important;
    font-family: RobotoLight !important;
  }

  .Change_address_Button {
    font-size: 16px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .customer_id {
    font-size: 16px !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }

  .sideDrawer {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .drawer_input_result_textfield {
    width: 55% !important;
    background-color: #fff;
    font-size: '40px !important';
    border-radius: 5px;
  }

  .drawer_input_width_height_stack {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: white;
    width: 55% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .applybutton_span {
    width: 280px;
    display: flex;
    color: #f4f4f4;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
  }

  .applybutton_span_disabled {
    float: left !important;
    width: 100%;
    display: flex;
    color: #f4f4f4;
    font-weight: normal;
    font-size: 18px;
    cursor: not-allowed;
  }

  .select_Track_Proposal_Apply_Covering_Page {
    min-width: 200px;
    width: auto;
    margin-left: 15px;
  }

  .show_Sku_Table_Body_Cell_Proposal_Apply_Covering_Page {
    border-bottom: 0px !important;
  }

  .show_Sku_Table_Cell_Proposal_Apply_Covering_Page {
    padding: 0 !important;
  }

  .search-table-box {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 768px) {

  /* proposal apply covering below 768 */
  .proposal_total_text {
    display: flex;
    flex-direction: column;
  }

  .proposal_total_text_2 {
    margin-left: -5px;
  }

  .pageNotFoundPageMainBox {
    height: 265px;
  }

  .pageNotFoundPageLogoIcon {
    width: 50% !important;
  }

  .pageNotFoundText404 {
    font-size: 100px !important;
  }

  .pageNotFoundText {
    font-size: 32px !important;
  }

  .stepperMainTextSize {
    font-size: 26px !important;
  }

  .ProposalstepperMainTextSize {
    font-size: 26px !important;
  }

  .stepperSubTextStyle {
    font-size: 14px !important;
  }

  .ProposalstepperSubTextStyle {
    font-size: 14px !important;
  }

  .CoveringstepperSubTextStyle {
    font-size: 14px !important;
  }

  .CoveringstepperMainTextSize {
    font-size: 26px !important;
  }

  .stepperSubStack {
    flex-direction: column !important;
  }

  .ProposalStepperStack {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
  }

  .CoveringStepperStack {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
  }

  .last_Name_Address_Proposal_Apply_Covering_Page {
    text-transform: capitalize !important;
  }

  .show_Sku_Box_Proposal_Apply_Covering_Page {
    display: flex !important;
    width: 100% !important;
    margin-top: 3.5% !important;
  }

  .show_Sku_Sub_Box_Proposal_Apply_Covering_Page {
    width: 100% !important;
  }

  .show_Sku_Table_Container_Proposal_Apply_Covering_Page {
    display: flex !important;
    background-color: white !important;
    width: 100%;
  }

  .show_Sku_Table_Cell_Proposal_Apply_Covering_Page {
    padding: 0 !important;
    font-weight: 550 !important;
    font-size: 16px !important;
    font-family: RobotoLight !important;
    border-bottom: 2px solid #e2e2e3 !important;
  }

  .show_Sku_Table_Body_Cell_Proposal_Apply_Covering_Page {
    padding: 0 !important;
    font-family: RobotoLight !important;
    border-bottom: 0px !important;
  }

  .show_Sku_Table_Pagination_Proposal_Apply_Covering_Page {
    width: 110% !important;
    min-width: 10 !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center !important;
  }

  .continue_Button_Box_Proposal_Apply_Covering_Page {
    width: 100% !important;
  }

  .padding_Continue_Button_Proposal_Apply_Covering_Page {
    line-height: 20px !important;
  }

  .padding_Cancel_Button_Proposal_Apply_Covering_Page {
    padding-bottom: 30px !important;
  }

  .select_Track_Proposal_Apply_Covering_Page {
    min-width: 200px;
    width: auto;
    margin-left: 15px;
  }

  .table-box {
    width: 350px;
    margin-right: -30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .search-table-box {
    width: 100% !important;
    margin-right: -30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .centered_box {
    margin-top: 20px !important;
    width: 350px !important;
  }

  .link2 {
    font-size: 18px !important;
  }

  .table-box {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .search-table-box {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (min-width: 280px) and (max-width: 768px) {
  .apply_covering_box {
    flex-grow: 1 !important;
    padding: 3 !important;
    width: 100% !important;
  }

  .table-dimension-cd-proposal1 {
    width: 780px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 30px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-cd-sales-order {
    width: 100% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .search-table-dimension {
    width: 160% !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 30px !important;
    color: #000;
    font-size: 16px !important;
  }

  .text_selectfield_copy {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield_copy_upper {
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield_upper {
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .filtertext_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_inputfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
  }

  .filtertext_inputfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
  }

  .proposal-select-window-table {
    width: auto !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .sales-order-adding-windows-table {
    width: 1500px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-cd-sales-order {
    width: 950px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

@media (min-width: 769px) and (max-width: 840px) {
  .apply_covering_box {
    flex-grow: 0 !important;
    padding: 3 !important;
    width: 100% !important;
  }

  .table-dimension-cd-sales-order {
    width: 900px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .search-table-dimension {
    width: 100px !important;

    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 30px !important;
    color: #000;
    font-size: 16px !important;
  }
}

/* Customer Type Media Query Ends*/

.centered_box {
  width: 300px;
  text-align: center !important;
  margin-top: 30px !important;
}

.list {
  border: 1px solid #e6e6e8 !important;
  margin-bottom: 5% !important;
  border-radius: 5px !important;
  font-size: 100% !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

/* pricing-page-style-start*/
.sample_Sku_Heading_Pricing_Page {
  font-size: 130% !important;
  margin-bottom: 10px;
  font-weight: 500;
}

.sampleSkuHeading {
  font-size: 130% !important;
  margin-bottom: -3% !important;
}

.example_Sku_Main_Box {
  border-radius: 5px;
  margin-top: 7%;
  padding-top: 1% !important;
  padding-bottom: 1%;
  width: 310px;
  box-shadow: 0px 0px 4px #888888, 0px 0px 4px #888888;
}

.example_Sku_Sub_Box {
  width: 92%;
  padding: -20px 5px 5px 5px;
  border-radius: 5px;
  margin-top: 0%;
}

.brand_Product_Label_Category_Font {
  font-weight: bold !important;
}

.sku_Details_Box_Item {
  width: 90%;

  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-top: 2%;
}

.example_Sku_Width_Height_Margin_One_value {
  margin-left: 55% !important;
}

.sample_Sku_width_Height {
  margin-left: 25% !important;
}

.sample_Sku_width_Height_Second {
  margin-left: 25% !important;
}

.Enter_Miles {
  margin-left: 10% !important;
}

.example_Sku_Width_Height_Margin_Second_Value {
  margin-left: 20% !important;
}

.example_Sku_Width_Height_Margin_Third {
  margin-left: 15%;
}

.example_Sku_Header_Bottom {
  margin-bottom: 2% !important;
  border-bottom: 0.5px solid grey !important;
}

.get_Pricing_Button {
  margin-left: 1% !important;
  margin-top: 15px !important;
  background-color: #008593 !important;
  border-radius: 25px !important;
  cursor: pointer !important;
}

.get_Pricing_Button:hover {
  background-color: #008593 !important;
}

.sample_Sku_Sixth {
  margin-right: 3%;
  margin-bottom: 10%;
}

.copy_Icon_Fifth {
  margin-left: 4% !important;
}

.copy_Icon_Second {
  margin-left: 3.4%;
}

.copy_Icon_Third {
  margin-left: 3.3%;
}

.copy_Icon_Forth {
  margin-left: 4.4%;
}

.sku_Input_Box_First {
  width: 80%;

  padding-bottom: 0%;
}

.sku_Input_Box {
  width: 80%;
  /* margin-top: 5%; */
  padding-top: 20px;
}

.sku_Price_Main_Box {
  background-color: #f2f2f2;
  width: 100%;
  border-radius: 5px;
  margin-top: 4%;
  box-shadow: 0px 0px 4px #888888, 0px 0px 4px #888888;
}

.sku_Price_Sub_Box {
  width: 100%;
  padding: 10px 5px 10px 5px;
  border-radius: 5px !important;
  font-size: 60% !important;
}

.sku_Error {
  color: red !important;
  margin-top: 200;
  padding-top: 5% !important;
}

.sku_Details_Header {
  margin-top: 15px !important;
  font-size: 130% !important;
}

.sku_Details_Main_Box {
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 4%;
  margin-bottom: 4%;
  width: 100%;
  box-shadow: 0px 0px 4px #888888, 0px 0px 4px #888888;
}

.sku_Details_Sub_Box {
  width: 90%;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.bottom_Space {
  width: 92%;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  margin-top: 2;
}

.blank_Space_Box_Width_Height_Pricing_Page {
  width: 100% !important;
  height: 405px !important;
  margin-top: 5% !important;
}

.pricing_Sample_Sku {
  margin-top: 3% !important;
  margin-bottom: 60px !important;
}

/* pricing-page-style-end*/

/* sku-page-style-start */

.sku_page_Fisrt_Box_Padding {
  padding-top: 5% !important;
  margin-top: 2% !important;
}

.sample_Sku_Heading {
  font-size: 130% !important;
  text-align: center !important;
  margin-bottom: 8px !important;
}

.sample_Sku_Main_Box {
  border-radius: 5px !important;
  margin-top: 0% !important;
  padding-top: 0% !important;
  padding-bottom: 1% !important;
  width: 310px !important;
  box-shadow: 0px 0px 4px #888888, 0px 0px 4px #888888 !important;
}

.sample_Sku_Sub_Box {
  width: 92% !important;
  padding: 5px 5px 5px 5px !important;
  border-radius: 5px !important;
  margin-top: 0% !important;
}

.sample_Sku_Box_Margin_Number {
  margin-right: 1.5% !important;
}

.sample_Sku_Box_Margin_Sku_Name {
  margin-right: 6.5% !important;
}

.sample_Sku_Box_Margin_Sku_Name_Second {
  margin-right: 6% !important;
}

.sample_Sku_Box_Margin_Sku_Name_Third {
  margin-right: 5% !important;
}

.copy_Icon_Sku_Page_First {
  margin-left: 3%;
}

.copy_Icon_Sku_Page_Second {
  margin-left: 2.6%;
}

.copy_Icon_Sku_Page_Forth {
  margin-left: 3.8%;
}

.copy_Icon_Sku_Page_Third {
  margin-left: 2%;
}

.sku_Input_Main_Box_width {
  width: 100% !important;
}

.sku_Input_Sub_Box_width_Before {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}

.sku_Input_Sub_Box_width_After {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}

.sample_sku_Main_Box_Border_Bottom {
  margin-bottom: 2% !important;
  border-bottom: 0.5px solid grey !important;
}

.input_field {
  margin-top: 0% !important;
  background-color: white !important;
  text-align: center !important;
}

.search_Button {
  margin-left: 3% !important;
  margin-top: 3% !important;
  cursor: pointer !important;
  background-color: #008593 !important;
  border-radius: 25px !important;
}

.search_Button:hover {
  background-color: #008593;
}

.sku_Details_Heading_Sku_Page {
  margin-top: 8% !important;
  font-size: 130% !important;
}

.sku_Details_Main_Box_Sku_Page {
  background-color: #f2f2f2 !important;
  border-radius: 5px !important;
  margin-top: 4% !important;
  margin-bottom: 4% !important;
  width: 100%;
  box-shadow: 0px 0px 4px #888888, 0px 0px 4px #888888 !important;
}

.sku_Type_Status_width {
  width: 92% !important;
  padding: 15px 5px 5px 5px !important;
  border-radius: 5px !important;
}

.brand_Product_Label_Margin {
  width: 92% !important;
  padding: 5px 5px 15px 5px !important;
  border-radius: 5px !important;
  margin-top: 2% !important;
}

.sku_page_Font_weight {
  font-weight: bold !important;
}

.category_width_Sku_Page {
  width: 100% !important;
}

.blank_Space_Box_Width_Height {
  width: 100% !important;
  height: 250px !important;
}

.bottom_Space_Sku_page {
  width: 92% !important;
  padding: 5px 5px 5px 5px !important;
  border-radius: 5px !important;
  margin-top: 2% !important;
}

/* sku-page-style-end */

/* login-page-style-start */

/* .First_Heading {
  margin-top: 1rem !important;
  font-weight: 200 !important;
} */

.image {
  width: 240px;
}

.formcontrol_Login_Page {
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  width: 100% !important;
}

.formcontrol_Login_Page_1 {
  width: 100% !important;
}

.formcontrol_Second_Login_Page {
  width: 100% !important;
}

.OutlinedInput_Login_page {
  background-color: #f4f4f4 !important;
}

.IconButton_Login_Page {
  color: #0ca8b0;
}

/* login-page-style-end */

/* header-page-style-start */

.wrapup_box {
  flex-grow: 1;
}

.drawer_Header_page {
  z-index: 100000 !important;
}

.Arrow_Circle_Right_Icon_Button {
  color: white !important;
}

.Account_Circle_Outlined_Icon_Button {
  color: white !important;
}

.drawer_Logout_Button {
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  background-color: white !important;
  border-radius: 45px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
  margin-top: 15% !important;
  font-size: 90% !important;
}

.drawer_Logout_Button:hover {
  background-color: white !important;
}

.header_back_button_typography {
  width: 100%;
  margin-top: -60px !important;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  font-size: 14px;
}

.header_page_name_of_screen_typography {
  width: 100%;
  margin-top: 20px !important;
  margin-left: -2px !important;
  position: fixed;
  z-index: 1 !important;
  background-color: #fff;
  font-size: 14px;
  text-align: center;
}

.header_page_hr {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.heade_page_disable_arrowicon {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  margin-left: 8% !important;
  margin-right: 8% !important;
}

/* header-page-style-end */

/* layout page style start */

.bottom_navigation_all {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1300;
  background: #093236 !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/* layout page style end */
.mismatchalert {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  font-size: large;
}

/* drawer input style start */

.drawer_input_width_height_stack {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: white;
}

.drawer_input_result_textfield {
  width: 100%;
  background-color: #fff;
  font-size: '40px !important';
  border-radius: 5px;
  margin: 5px;
}

.drawer_input_apply_button {
  color: black !important;
  border: 1px solid black !important;
  background-color: white !important;
  border-radius: 25px !important;
  margin: 10px !important;
  font-size: 15px !important;
}

.drawer_input_apply_button:hover {
  background-color: white;
}

.drawer_input_buttons_row {
  justify-content: center;
  padding-bottom: 20px;
}

.drawer_input_buttons_row_1 {
  justify-content: center;
}

.drawer_input_buttons_row_Bottom {
  justify-content: center;
  margin-bottom: 20px;
}

.drawer_input_buttons_style {
  --width: 65px !important;
  height: var(--width);
  min-width: var(--width);
  border-radius: 10% !important;
  border: 1px solid black !important;
  background-color: white !important;
  font-family: 'RobotoLight';
  font-weight: 100 !important;
  font-size: 50px !important;
  color: #333 !important;
}

.margin_left_button {
  margin-left: 20px !important;
}

.drawer_input_buttons_style_1 {
  --width: 65px !important;
  width: var(--width);
  height: var(--width);
  border: 1px solid black !important;
  background-color: white !important;
  font-family: 'RobotoLight';
  font-weight: 100 !important;
  font-size: 50px !important;
  color: #333 !important;
}

.calculator_drawer_digit_box {
  width: 280px !important;
}

.calculator_drawer_digit_box_1 {
  height: 272px !important;
  width: 200px !important;
}

.button_margin_top_calculator {
  padding-bottom: 18px !important;
  justify-content: center !important;
}

.button_margin_top_calculator_1 {
  padding-bottom: 24px !important;
  justify-content: center !important;
}

.calculator_drawer_digit_box_2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-sizing: content-box !important;
}

.MarginLeft {
  margin-left: 10% !important;
}

.MarginRight {
  margin-right: 10% !important;
}

.drawer_input_buttons_style2 {
  --width: 50px !important;
  width: var(--width);
  height: var(--width);
  border: 1px solid black !important;
  background-color: white !important;
  font-family: 'RobotoLight';
  font-weight: 100 !important;
  font-size: 16px !important;
  color: #333 !important;
}

.drawer_input_fraction_buttons {
  margin-left: 20px !important;
}

.buttonIcon {
  font-family: 'RobotoLight';
  font-size: 50px !important;
  font-weight: 100 !important;
  color: #adadad;
}

.drawer_input_buttons_style2:hover {
  background-color: #d3d3d3 !important;
}

.drawer_input_buttons_style:hover {
  background-color: #d3d3d3 !important;
}

/* drawer input style end */

/* editing proposal window set to add windows page style start */

.prompt_typography_style {
  font-size: 17px !important;
  color: white !important;
}

.epwstaw_epswtaw_table_container {
  background-color: white;
  width: 100%;
}

.epwstaw_epswtaw_link {
  color: #3696be;
  font-size: 16px !important;
  text-decoration: inherit !important;
}

/* editing proposal window set to add windows page style end */

/* proposal details page style start */

.snack_Bar_Proposal_Details_Page {
  width: 100% !important;
}

.proposal_Name_Last_Name_Address_Box_Proposal_Details_Page {
  text-align: center !important;
}

.details_Main_Box_Proposal_Details_Page {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex !important;
  width: 100% !important;
}

.details_Sub_Box_Proposal_Details_Page {
  display: flex !important;
  margin-left: 0% !important;
  width: 100% !important;
  justify-content: space-between;
}

.cust_Type_DC_Installation_Removelfee_Additional_Tax_Box {
  width: 60% !important;
}

.font_Size_Proposal_Details_Page {
  font-size: 100% !important;
}

.total_Margin_Font_Proposal_Details_Page {
  color: #333333;
  font-weight: bold !important;
  margin-top: 8% !important;
}

.Coverings_Subtotal_Proposal_Details_Page {
  color: #333333;
  font-weight: bold !important;
}

.retail_Box_Width_Proposal_Details_Page {
  text-align: right;
  width: 40% !important;
}

.all_Button_Style_Proposal_Details_Page {
  margin-bottom: 0% !important;
  padding: 0 !important;
}

.edit_Window_List_Margin {
  margin-bottom: 0% !important;
}

.save_Proposal_icon_Button {
  width: 40px !important;
  height: 40px !important;
}

.convert_To_Sales_Order_Button {
  width: 42px !important;
  height: 32px !important;
}

.share_Proposal_Button {
  width: 40px !important;
  height: 40px !important;
}

.formcontrol-disabled {
  background-color: #e0e0e0;
  cursor: not-allowed !important;
  pointer-events: all !important;
}

/* proposal details page style end */

/* edit proposal details page style start */

.add_Window_Button_margin_Bottom {
  margin-bottom: 0% !important;
}

.reorder_Windows_Button {
  width: 12px !important;
  height: 15px !important;
}

.window_Icon_Edit_Proposal_Details {
  width: 35px !important;
  height: 40px !important;
  position: absolute !important;
}

/* edit proposal details page style end */

/* reorder proposal deatails style start */

.reorder_Main_Box {
  display: flex !important;
  width: 100% !important;
}

.reorder_Sub_Box {
  width: 100% !important;
}

.reorder_Table_Container {
  display: flex !important;
  background-color: white !important;
  width: 100% !important;
}

.reorder_Table_Head_Cell {
  font-size: 100% !important;
  border-bottom: 2px solid #e2e2e3;
}

.reorder_Table_Body_Cell {
  font-size: 100% !important;
  font-family: RobotoLight !important;
}

.apply_Changes_Button_Reorder {
  width: 43px !important;
  height: 45px !important;
  margin-left: 5px !important;
}

.apply_Changes_Box {
  margin-bottom: 0% !important;
}

/* reorder proposal deatails style end */

/* duplicate proposal details style start */

.margin_Bottom_duplicate_Proposal_Details {
  margin-bottom: 0% !important;
}

/* duplicate proposal details style end */

/* sku page media query start */

@media (min-width: 1200px) {
  .notes_textfield {
    width: 50%;
  }

  .drawer_textfield {
    width: 50%;
  }

  .drawer_iconbutton {
    color: #ffffff !important;
  }

  .drawer_textfield_1 {
    width: 50%;
  }

  .sample_Sku_Heading {
    font-size: 30px !important;
    padding-top: 0% !important;
    margin-top: 0% !important;
    text-align: center !important;
    margin-bottom: 14px !important;
  }

  .sample_Sku_Main_Box {
    margin-top: 5.5% !important;
    padding-top: 0.1% !important;
    width: 350px !important;
  }

  .sku_page_Fisrt_Box_Padding {
    padding-top: 0% !important;
    margin-top: -2% !important;
  }

  .sku_Details_Heading_Sku_Page {
    margin-top: 2% !important;
  }

  .search_Button {
    margin-left: 0% !important;
    margin-top: 1.5% !important;
  }

  .copy_Icon_Sku_Page_Third {
    margin-left: 8px !important;
  }

  .copy_Icon_Sku_Page_Forth {
    margin-left: 10.5px !important;
  }

  .sku_Input_Sub_Box_width_Before {
    margin-top: 20px !important;
  }

  .sku_Input_Sub_Box_width_After {
    margin-top: 20px !important;
  }

  .sku_Details_Main_Box_Sku_Page {
    margin-top: 2% !important;
    width: 1000px !important;
  }

  .copy_Icon_Sku_Page_First {
    margin-left: 10px;
  }

  .copy_Icon_Sku_Page_Second {
    margin-left: 10px;
  }

  .sku_type_status_brand_product_label {
    width: 333px !important;
  }

  .category_width_Sku_Page {
    width: 999px !important;
  }

  .blank_Space_Box_Width_Height {
    height: 250px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .drawer_input_result_textfield {
    width: 55% !important;
    background-color: #fff;
    font-size: '40px !important';
    border-radius: 5px;
  }

  .drawer_input_width_height_stack {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: white;
    width: 55% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .sample_Sku_Heading {
    font-size: 30px !important;
    padding-top: 0% !important;
    margin-top: 0% !important;
    text-align: center !important;
  }

  .sample_Sku_Main_Box {
    margin-top: 5.5% !important;
    padding-top: 0.01% !important;
    width: 350px !important;
  }

  .sku_page_Fisrt_Box_Padding {
    padding-top: 2% !important;
    margin-top: -3% !important;
  }

  .sku_Details_Heading_Sku_Page {
    margin-top: 2% !important;
  }

  .search_Button {
    margin-left: 0% !important;
    margin-top: 1.5% !important;
  }

  .sku_Input_Sub_Box_width_Before {
    margin-top: 20px !important;
  }

  .sku_Input_Sub_Box_width_After {
    margin-top: 20px !important;
  }

  .sku_Details_Main_Box_Sku_Page {
    margin-top: 2% !important;
    width: 800px !important;
  }

  .copy_Icon_Sku_Page_First {
    margin-left: 1%;
  }

  .copy_Icon_Sku_Page_Second {
    margin-left: 1.3%;
  }

  .copy_Icon_Sku_Page_Third {
    margin-left: 0.2%;
  }

  .copy_Icon_Sku_Page_Forth {
    margin-left: 1.6%;
  }

  .sku_type_status_brand_product_label {
    width: 333px !important;
  }

  .category_width_Sku_Page {
    width: 999px !important;
  }

  .blank_Space_Box_Width_Height {
    height: 239px !important;
  }

  .search-table-box {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ADD_CUSTOMER_TEXTFIELD {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    margin-top: 10px !important;
    width: 50%;
  }

  .ADD_CUSTOMER_TEXTFIELD_1 {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    width: 50%;
  }

  .text_selectfield_upper {
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .filtertext_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .table-dimension-cd-sales-order {
    width: 950px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

@media (min-width: 769px) and (max-height: 1024px) {
  .measurement_fractio_type_2 {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .notes_textfield {
    width: 50% !important;
  }

  .drawer_textfield {
    width: 50% !important;
  }

  .drawer_textfield_1 {
    width: 50% !important;
  }

  .sample_Sku_Heading {
    font-size: 23px !important;
    padding-top: 0% !important;
    margin-top: 0% !important;
    text-align: center !important;
    margin-bottom: 6px !important;
  }

  .sample_Sku_Main_Box {
    margin-top: 5.5% !important;
    padding-top: 0.05% !important;
    width: 350px !important;
  }

  .sku_page_Fisrt_Box_Padding {
    padding-top: 2% !important;
    margin-top: -5% !important;
  }

  .sku_Details_Heading_Sku_Page {
    margin-top: 2% !important;
  }

  .search_Button {
    margin-left: 0% !important;
    margin-top: 1.5% !important;
  }

  .sku_Input_Sub_Box_width_Before {
    margin-top: 20px !important;
  }

  .sku_Input_Sub_Box_width_After {
    margin-top: 20px !important;
  }

  .sku_Details_Main_Box_Sku_Page {
    margin-top: 2% !important;
    width: 600px !important;
  }

  .copy_Icon_Sku_Page_First {
    margin-left: 3%;
  }

  .copy_Icon_Sku_Page_Second {
    margin-left: 3% !important;
  }

  .copy_Icon_Sku_Page_Third {
    margin-left: 2.2% !important;
  }

  .copy_Icon_Sku_Page_Forth {
    margin-left: 3.8% !important;
  }

  .sku_type_status_brand_product_label {
    width: 333px !important;
  }

  .category_width_Sku_Page {
    width: 999px !important;
  }

  .blank_Space_Box_Width_Height {
    height: 222px !important;
  }

  .table-dimension-cd-sales-order {
    width: 950px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

/* sku page media query end */

/* pricing page media query start */

@media (min-width: 1200px) {
  .sample_Sku_Heading_Pricing_Page {
    font-size: 30px !important;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
  }

  .sku_Input_Box_First {
    padding-top: 0% !important;
  }

  .sku_Input_Box {
    padding-top: 0% !important;
  }

  .get_Pricing_Button {
    margin-left: 0% !important;
    margin-top: 2% !important;
  }

  .input_width_Pricing_page {
    margin-left: 4% !important;
  }

  .input_Height_Pricing_page {
    margin-left: 4% !important;
  }

  .copy_Icon_First {
    margin-left: 10% !important;
  }

  .copy_Icon_Second {
    margin-left: 9.3% !important;
  }

  .copy_Icon_Third {
    margin-left: 9.3% !important;
  }

  .copy_Icon_Forth {
    margin-left: 9.9% !important;
  }

  .copy_Icon_Fifth {
    margin-left: 10.8% !important;
  }

  .copy_Icon_sixth {
    margin-left: 9.8% !important;
  }

  .sku_Details_Header {
    margin-top: 0% !important;
  }

  .sku_Price_Main_Box {
    margin-top: 2% !important;
    width: 970px;
  }

  .sku_Details_Header {
    margin-top: 1.5% !important;
  }

  .sku_Details_Main_Box {
    margin-top: 2% !important;
    width: 970px !important;
    margin-bottom: 1%;
  }

  .category_width_Pricing_Page {
    width: 999px !important;
  }

  .sku_type_status_brand_product_label_Pricing_page {
    width: 633px !important;
  }

  .example_Sku_Width_Height_Margin_One {
    margin-left: 40% !important;
  }

  .example_Sku_Width_Height_Margin_Second {
    margin-left: 20% !important;
  }

  .example_Sku_Width_Height_Margin_One_Value {
    margin-left: 28% !important;
  }

  .example_Sku_Width_Height_Margin_Second_Value {
    margin-left: 25% !important;
  }

  .Enter_Miles {
    margin-left: 27% !important;
  }

  .example_Sku_Width_Height_Margin_Third {
    margin-left: 27%;
  }

  .example_Sku_Width_Height_Margin_Second_Value {
    margin-left: 25% !important;
  }

  .pricing_Sample_Sku {
    margin-top: 3% !important;
    margin-bottom: 60px !important;
  }

  .blank_Space_Box_Width_Height_Pricing_Page {
    height: 386px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .sample_Sku_Heading_Pricing_Page {
    font-size: 25px !important;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
  }

  .example_Sku_Main_Box {
    width: 350px !important;
    margin-top: 1% !important;
  }

  .sku_Input_Box_First {
    padding-top: 0% !important;
  }

  .sku_Input_Box {
    padding-top: 0% !important;
  }

  .get_Pricing_Button {
    margin-left: 0% !important;
    margin-top: 2% !important;
  }

  .input_width_Pricing_page {
    margin-left: 4% !important;
  }

  .input_Height_Pricing_page {
    margin-left: 4% !important;
  }

  .copy_Icon_First {
    margin-left: 10% !important;
  }

  .copy_Icon_Second {
    margin-left: 9.3% !important;
  }

  .copy_Icon_Third {
    margin-left: 9.3% !important;
  }

  .copy_Icon_Forth {
    margin-left: 9.9% !important;
  }

  .copy_Icon_Fifth {
    margin-left: 10.9% !important;
  }

  .copy_Icon_sixth {
    margin-left: 10% !important;
  }

  .sku_Details_Header {
    margin-top: 2% !important;
  }

  .sku_Price_Main_Box {
    margin-top: 2% !important;
    width: 800px !important;
  }

  .sku_Details_Header {
    margin-top: 4% !important;
  }

  .sku_Details_Main_Box {
    margin-top: 2% !important;
    width: 800px !important;
    margin-bottom: 1%;
  }

  .category_width_Pricing_Page {
    width: 999px !important;
  }

  .sku_type_status_brand_product_label_Pricing_page {
    width: 633px !important;
  }

  .example_Sku_Width_Height_Margin_One {
    margin-left: 40% !important;
  }

  .example_Sku_Width_Height_Margin_Second {
    margin-left: 20% !important;
  }

  .example_Sku_Width_Height_Margin_One_Value {
    margin-left: 28% !important;
  }

  .Enter_Miles {
    margin-left: 27% !important;
  }

  .example_Sku_Width_Height_Margin_Second_Value {
    margin-left: 25% !important;
  }

  .example_Sku_Width_Height_Margin_Third {
    margin-left: 27%;
  }

  .example_Sku_Width_Height_Margin_Second_Value {
    margin-left: 25% !important;
  }

  .pricing_Sample_Sku {
    margin-top: 3% !important;
    margin-bottom: 60px !important;
  }

  .blank_Space_Box_Width_Height_Pricing_Page {
    height: 400px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .back-button_1 {
    margin-left: 45px !important;
  }

  .sample_Sku_Heading_Pricing_Page {
    font-size: 25px !important;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
  }

  .example_Sku_Main_Box {
    width: 350px !important;
    margin-top: 2% !important;
  }

  .sku_Input_Box_First {
    padding-top: 1% !important;
  }

  .sku_Input_Box {
    padding-top: 1% !important;
  }

  .get_Pricing_Button {
    margin-left: 0% !important;
    margin-top: 2% !important;
  }

  .input_width_Pricing_page {
    margin-left: 4% !important;
  }

  .input_Height_Pricing_page {
    margin-left: 4% !important;
  }

  .copy_Icon_First {
    margin-left: 10% !important;
  }

  .copy_Icon_Second {
    margin-left: 8.6% !important;
  }

  .copy_Icon_Third {
    margin-left: 8.6% !important;
  }

  .copy_Icon_Forth {
    margin-left: 9.4% !important;
  }

  .copy_Icon_Fifth {
    margin-left: 10.8% !important;
  }

  .copy_Icon_sixth {
    margin-left: 9.5% !important;
  }

  .sku_Details_Header {
    margin-top: 2% !important;
  }

  .sku_Price_Main_Box {
    margin-top: 2% !important;
    width: 600px !important;
  }

  .sku_Details_Header {
    margin-top: 4% !important;
  }

  .sku_Details_Main_Box {
    margin-top: 2% !important;
    width: 600px !important;
    margin-bottom: 1%;
  }

  .category_width_Pricing_Page {
    width: 999px !important;
  }

  .sku_type_status_brand_product_label_Pricing_page {
    width: 633px !important;
  }

  .example_Sku_Width_Height_Margin_One {
    margin-left: 40% !important;
  }

  .example_Sku_Width_Height_Margin_Second {
    margin-left: 20% !important;
  }

  .example_Sku_Width_Height_Margin_One_Value {
    margin-left: 28% !important;
  }

  .Enter_Miles {
    margin-left: 27% !important;
  }

  .example_Sku_Width_Height_Margin_Second_Value {
    margin-left: 25% !important;
  }

  .example_Sku_Width_Height_Margin_Third {
    margin-left: 27%;
  }

  .example_Sku_Width_Height_Margin_Second_Value {
    margin-left: 25% !important;
  }

  .blank_Space_Box_Width_Height_Pricing_Page {
    height: 370px !important;
  }

  .text_selectfield_copy {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield_copy_upper {
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield_upper {
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .filtertext_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_inputfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
  }

  .filtertext_inputfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
  }

  .ADD_CUSTOMER_TEXTFIELD {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    margin-top: 10px !important;
    width: 50%;
  }

  .ADD_CUSTOMER_TEXTFIELD_1 {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .sideDrawer {
    padding-left: 60px !important;
    padding-right: 90px !important;
  }

  .notes_textfield {
    width: 50% !important;
  }

  .drawer_textfield {
    width: 50% !important;
  }

  .drawer_textfield_1 {
    width: 50% !important;
  }

  .previewProposalPdf {
    width: 550px !important;
    margin-left: -14%;
  }

  .short_textfield {
    width: 30% !important;
  }

  .short_textfield_additional {
    margin-left: 20% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 80%;
  }

  .email_short_textfield {
    width: 50% !important;
  }

  .remeasure_error_msg {
    width: 50% !important;
  }

  .back-button {
    margin-left: 80% !important;
  }

  .back-button_1 {
    margin-left: 28px !important;
  }

  .paragraph {
    width: 50% !important;
  }

  .default_field_paragraph {
    width: 50%;
  }

  .text_selectfield_copy {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield_copy_upper {
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield_upper {
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
    color: #000;
    font-weight: bold !important;
  }

  .short_textfield {
    margin-left: 20% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50%;
  }

  .short_textfield_additional {
    margin-left: 20% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 80%;
  }

  .email_short_textfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50%;
  }

  .filtertext_selectfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
    color: #000;
    font-weight: bold !important;
  }

  .text_inputfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 50% !important;
  }

  .filtertext_inputfield {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100% !important;
  }

  .ADD_CUSTOMER_TEXTFIELD {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    margin-top: 10px !important;
    width: 50%;
  }

  .ADD_CUSTOMER_TEXTFIELD_1 {
    font-family: 'RobotoLight';
    background-color: #f4f4f4;
    width: 50%;
  }
}

@media (min-width: 375px) and (max-width: 575px) {
  .sideDrawer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    overflow-x: hidden;
  }
}

.emailIDs {
  text-align: left;
}

/* pricing page media query end */

/* proposal table page style start */

.proposal_table_main_box {
  display: flex;
  width: 100%;
}

.proposal_table_sub_box {
  width: 100%;
  flex-flow: column;
}

.proposal_table_table_container {
  display: flex;
  background-color: white;
  width: 100%;
}

.proposal_table_table_cell {
  font-size: 100%;
  border-bottom: 2px solid #e2e2e3;
}

/* proposal table details page style end */

/* price drawer style start  */
.price_drawer_box {
  display: flex;
  justify-content: space-between;
  color: white;
}

.price_drawer_box:not(:last-child) {
  padding-bottom: 0.25em;
}

.price_drawer_box_1 {
  display: flex;
  justify-content: space-between;
  color: white;
}

.price_drawer_box_3 {
  display: flex;
  color: white;
  align-items: center;
}

.price_drawer_box_2 {
  display: flex;
  justify-content: space-between;
  color: white;
  flex-direction: column;
}

.emailIDs {
  text-align: left;
}

.color {
  color: black !important;
  font-weight: 100;
}

.disabled {
  opacity: 0.4;
}

.save_Proposal_Button {
  width: 20px !important;
  height: 20px !important;
  margin-left: 5px !important;
}

.drawer_Clear_Button {
  width: 130px;
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  background-color: white !important;
  border-radius: 45px !important;

  padding-left: 1 !important;
  padding-right: 1 !important;
  margin-top: 10px !important;
  font-size: 100% !important;
  margin-left: -160px !important;
}

.drawer_Clear_Button_disable {
  width: 130px;
  color: rgb(117, 116, 116) !important;
  border: 1px solid #c4c4c4 !important;
  background-color: rgb(224, 224, 224) !important;
  border-radius: 45px !important;

  padding-left: 1 !important;
  padding-right: 1 !important;
  margin-top: 10px !important;
  font-size: 100% !important;
  margin-left: -160px !important;
}

.drawer_textfieldcolor {
  background-color: white;
  margin-bottom: 20px !important;
}

.drawer_textfieldcolor_1 {
  background-color: white;
  margin-bottom: 10px !important;
}

.drawer_textfieldcolor_2 {
  background-color: white;
  margin-bottom: 0px !important;
}

.drawer_backbutton {
  margin-left: 90%;
}

.drawer_iconsize {
  width: 28px;
  height: 25px;
  margin-left: 0;
  position: relative;
  right: 13px;
}

.drawer_iconsize2 {
  width: 28px;
  height: 25px;
  margin-left: 0;
  position: relative;
}

.drawer_iconsize_Proposal_windowset {
  width: 28px;
  height: 25px;
  position: relative;
  right: 14px;
}

.drawer_iconsize_proposal_name {
  width: 23px;
  height: 22px;
  position: relative;
  right: 14px;
}

.drawer_iconsize1 {
  width: 25px;
  height: 25px;
}

.drawer_iconsize_send {
  width: 25px;
  height: 25px;
}

.drawer_cleariconsize {
  width: 20px;
  height: 20px;
}

.togglebutton_iconsize {
  width: 135px;
  height: 35px;
}

/* price drawer style end */

.rowhover:hover .sticky-column {
  background-color: #f5f5f5;
}

.rowhover:hover .sticky-columnws-1 {
  background-color: #f5f5f5;
}

.rowhover:hover .sticky-columnws-2 {
  background-color: #f5f5f5;
}

.rowhover:hover .sticky-column-2 {
  background-color: #f5f5f5;
}

.rowhover:hover .sticky-column-1 {
  background-color: #f5f5f5;
}

.all_ready_selectedwindows {
  background-color: #fff;
  font-size: 100%;
}

.all_ready_selectedwindows:hover {
  background-color: #f5f5f5;
}

/* Customer search start*/

.dayFormat {
  text-align: center !important;
  text-transform: uppercase !important;
  font-size: 36px !important;
  padding-top: 20px !important;
}

.dateFormat {
  text-align: center;
  margin: auto;
  text-transform: uppercase;
  font-size: 16px;
  padding-right: 5px;
}

.Customer_serach_date_formats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Search_Add_Customer {
  margin-left: 7px !important;
  font-weight: 400 !important;
}

.CustomerSearch_Appointment_MainDiv {
  display: flex;
  flex-direction: column;
}

.search_customer_heading {
  font-weight: bold !important;
  font-size: 15px !important;
  padding-bottom: 10px !important;
  font-family: 'RobotoLight' !important;
}

.search_customer_heading {
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: 'RobotoLight' !important;
}

.searchCustomer {
  background-color: #f4f4f4 !important;
  color: #0ca8b0 !important;
  height: 75px;
  font-size: 17px !important;
  font-weight: bold;
}

.shadow {
  box-shadow: '0px 5px 20px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important';
}

.Appointment {
  background-color: #f4f4f4 !important;
  color: #0ca8b0 !important;
  height: 75px;
  font-size: 17px !important;
  font-weight: bold;
  padding-right: 63px !important;
}

.dbtools {
  background-color: #f4f4f4 !important;
  color: #0ca8b0 !important;
  height: 75px;
  font-size: 17px !important;
  font-weight: bold;
  padding-right: 63px !important;
}

.my_appointments {
  margin-left: 7px !important;
  font-weight: 400 !important;
}

.search_details {
  margin-left: 7px !important;
  font-weight: 400 !important;
}

.search_details_save_schedule_appointemt {
  margin-left: 7px !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.search_details1 {
  margin-left: 5px !important;
  font-weight: 500 !important;
}

.search_details_button_search {
  color: #0ca8b0 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  position: relative !important;
  right: 10px !important;
  width: fit-content !important;
}

.search_details_button_create {
  color: #0ca8b0 !important;
  font-size: 18px !important;
  font-weight: 400 !important;

  position: relative !important;
  right: 10px !important;
  width: fit-content !important;
}

.search_details_button {
  color: #0ca8b0 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  position: relative !important;
  right: 10px !important;
  width: fit-content !important;
}

.search_details_button_1 {
  color: #0ca8b0 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  position: relative !important;
  width: fit-content !important;
}

.search_details_button_right {
  right: 2px !important;
}

.UserIcon {
  width: 43px !important;
  height: 35px !important;
  position: absolute !important;
}

.Create_new_Customer_logo {
  width: 45px !important;
  height: 40px !important;
  position: absolute !important;
}

.Create_new_Customer_logo_1 {
  width: 40px !important;
  height: 35px !important;
  position: absolute !important;
  margin-left: 4px !important;
}

.finalize_fab_icon {
  width: 60px !important;
}

.left_left {
  margin-left: 10px !important;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .searchCustomer {
    width: 350px;
    margin: 10px auto !important;
  }
}

@media (min-width: 1025px) {
  .searchCustomer {
    width: 450px;
  }

  .Appointment {
    width: 450px;
  }

  .CustomerSearch_Appointment_MainDiv {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Add customer form */

.new_customer_address {
  font-weight: bold;
  margin-bottom: 20px;
}

.ADD_CUSTOMER_TEXTFIELD {
  font-family: 'RobotoLight';
  background-color: #f4f4f4;
  margin-top: 10px !important;
}

.ADD_CUSTOMER_TEXTFIELD_1 {
  font-family: 'RobotoLight';
  background-color: #f4f4f4;
}

.paragraph {
  font-family: 'RobotoLight' !important;
  font-size: 15px !important;
  color: #2d3030 !important;
}

/* Customer search end*/

/* appointment module  */

.font_weight_bold {
  font-weight: bold !important;
}

.font_size {
  font-size: 15px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.font_size_1 {
  font-size: 15px !important;
}

.appointment_font_size {
  font-size: 15px !important;
}

.padding_bottom {
  padding-bottom: 20px !important;
}

.ResultCustomerData {
  color: '#008593';
}

.appointment_calender_icon {
  width: 40px !important;
  height: 40px !important;
  position: absolute !important;
}

.schedule_appointment_box {
  display: flex;
  flex-direction: column;
}

.schedule_appointment_width {
  width: 100%;
}

.schedule_appointment_stack_margin_bottom {
  margin-bottom: 10px;
}

.appointments_timing_color {
  color: black;
}

.appointments_update_button {
  color: #008593;
}

.all_appointments_box {
  display: flex;
  width: 100%;
}

.edit_phone_email_box {
  margin-left: 15px;
  margin-top: 0px;
}

.editIconCancelIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

.edit_phone_email_box_3 {
  display: flex;
  justify-content: space-between;
}

.edit_phone_email_box_1 {
  margin-top: 5px;
}

.edit_phone_email_box_2 {
  margin-left: -14px;
}

.search_result_table {
  color: #0ca8b0;
  cursor: pointer;
  text-transform: capitalize;
  white-space: nowrap;
}

.search_table_header1 {
  width: 25% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.search_table_header2 {
  width: 15% !important;
  white-space: nowrap;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.search_table_header5 {
  width: 18% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.search_table_header3 {
  width: 30% !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  color: #000;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.search_table_header4 {
  width: 5% !important;
  padding: 0px !important;
  white-space: nowrap;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.search_table_header6 {
  width: 10% !important;
  white-space: nowrap;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.small_cell {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 5px !important;
}

.small_cell2 {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
  width: 5px !important;
}

.small_header {
  width: 5px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
}

.proposal_result_header {
  font-weight: bold;
  font-size: 14px !important;
  color: #333333;
  font-family: 'Roboto' !important;
  line-height: 20px;
}

.convert_sales_order_window_list {
  margin: 6px 0px;
}

.convert_sales_order_window_list_color {
  color: #aa0d0d;
}

.edit_gray_button {
  position: relative;
  width: 100%;
  display: flex;
  color: #0ca8b0;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
}

.edit_gray_button_1 {
  position: relative;
  width: 100%;
  display: flex;
  color: #0ca8b0;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  z-index: 0 !important;
  margin-left: -24px !important;
}

.new_appointment_edit_appointment {
  display: flex;
  justify-content: space-between;
}

.main {
  margin: 4rem 8% 4rem;
}

.main .previewproposal {
  align-items: center;
  margin-left: -5%;
  margin-right: -5%;
  margin-top: 110px;
  margin-bottom: 50px;
}

.hosted-field {
  height: 32px;
  margin-bottom: 1em;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
}

.marginLeftCustomerInfo {
  margin-left: 20px !important;
}

.Customer_name_box {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  width: 100% !important;
}

.Customer_fullname {
  color: #101010e8 !important;
  font-family: 'RobotoLight' !important;
  font-size: 30px !important;
  line-height: 1 !important;
  text-transform: capitalize !important;
  width: 50% !important;
  padding-top: 10px !important;
}

.edit_icon_customername {
  position: relative;
  bottom: 8px;
  margin-top: 20px;
}

.Calender_stack {
  margin-top: 20px !important;
}

.Location_Box {
  width: fit-content;
  position: relative;
  vertical-align: top;
}

.View_this_address {
  margin-top: 10px !important;
  display: flex;
}

.billing_address {
  position: relative;
  bottom: 4px;
  left: 6px;
}

.view_this_address {
  position: relative;
  left: 5px;
}

.billing_address_input {
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
  margin-left: 21px !important;
}

.view_this_address_input {
  height: 19px !important;
  width: 19px !important;
  vertical-align: middle !important;
  margin-left: 20px !important;
}

.View_this_address input[type='radio'] {
  accent-color: #232323d1;
}

.invalid_email {
  display: flex !important;
  color: #b42a2a !important;
  position: relative !important;
}

.valid_email {
  display: flex !important;
  color: green !important;
  position: relative !important;
}

.disable_submit_box_button {
  justify-content: flex-start;
  position: relative;
  right: 10px;
  margin-top: 20px;
  float: left;
}

.disable_submit_box_button_1 {
  justify-content: flex-start;
  position: relative;
  right: 10px;
  float: left;
}

.submit_button_customername {
  justify-content: flex-start;
  position: relative;
  right: 10px;
  margin-top: 20px;
  float: left;
  color: #0ca8b0 !important;
}

.userimage {
  width: 40px !important;
  height: 35px !important;
  position: absolute !important;
  margin-left: 4px !important;
}

.edit_close_box {
  display: flex !important;
  justify-content: space-between;
  width: 100px;
  position: relative;
  bottom: 18px;
}

.zipcodeError {
  text-align: left;
  padding: 0px;
  font-size: 12px !important;
  height: 6px;
  position: relative;
  bottom: 10px;
  left: 6px;
}

.notes_textfield {
  width: 100%;
  margin-top: 20px !important;
  background-color: white !important;
  border: 0;
  margin-bottom: 20px !important;
}

.switch_icon_before {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  opacity: 0.4;
}

.switch_icon_after {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 20px;
}

.break {
  max-width: 150px !important;
  min-width: 150px !important;
  word-wrap: break-word !important;
}

.table-headerps {
  width: 5px !important;
  white-space: nowrap;
  padding: 0px !important;
  padding-right: 30px !important;
  white-space: 'nowrap';
  color: #000;
  font-size: 16px !important;
}

.table-headerpsid {
  width: 5px !important;
  padding: 0px !important;
  margin-left: 2% !important;
  padding-right: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

.table-headerpsredid {
  width: 5px !important;
  text-align: right !important;
  padding: 0px !important;
  margin-left: 2% !important;
  padding-right: 15px !important;
  color: #000;
  font-size: 16px !important;
}

.checkbox-input {
  display: none;
}

.checkbox-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 15px;
  margin-right: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  padding-bottom: 20px !important;
}

/* style the custom checkbox when it's checked */
.checkbox-input:checked+.checkbox-icon::before {
  content: '\2713';
  font-size: 14px;
  line-height: -10px;
  text-align: center;
  color: #000;
  border-color: #007bff;
  font-weight: bold;
  padding-left: 4px !important;
}

/* style the label */
.checkbox-label {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  line-height: 1.2;
  cursor: pointer;
}

/* notes drawer  */

.attach_photo_box_1 {
  display: flex !important;
  margin-bottom: 20px !important;
  margin-left: 30px !important;
  align-items: center !important;
}

.attach_photo_box_2 {
  width: 30px !important;
  height: 30px !important;
  background-color: white !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.CameraAltIcon {
  color: #008593 !important;
  font-size: 20px !important;
}

.note_content_div {
  display: flex !important;
  margin: 5px 0px 5px 0px !important;
}

.notes_checkbox {
  zoom: 0.8 !important;
  padding-top: 0px !important;
  color: white !important;
}

.notes_minimizeicon {
  color: #008593 !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
}

.notes_color {
  color: #008593 !important;
}

.notes_save_button_box_1 {
  display: flex !important;
  margin-bottom: 20px !important;
  margin-left: 0px !important;
  align-items: center !important;
  cursor: pointer !important;
}

.notes_save_button_box_2 {
  width: 40px !important;
  height: 40px !important;
  background-color: white !important;
  border-radius: 50% !important;
  margin-top: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 0px !important;
}

.notes_save_button_box_2_disabled {
  width: 40px !important;
  height: 40px !important;
  background-color: rgb(202, 201, 201) !important;
  border-radius: 50% !important;
  margin-top: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 0px !important;
}

.notes_cancel_button_box {
  display: flex !important;
  margin-bottom: 20px !important;
  margin-left: 10px !important;
  align-items: center !important;
  cursor: pointer !important;
}

hr {
  border: none !important;
  height: 1px !important;
  background-color: #d6d6d6 !important;
  margin: 0px !important;
}

.my-custom-toolbar {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.my-custom-caption {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.my-custom-select-root {
  padding: 100px !important;
}

.my-custom-select {
  padding: 100px !important;
}

.my-custom-select-icon {
  padding: 100px !important;
}

.my-custom-input {
  padding: 100px !important;
}

.my-custom-menu-item {
  padding: 100px !important;
}

.csp {
  width: auto;
  overflow-x: hidden;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  background: #0d45a5;
}

@media (min-width: 350px) and (max-width: 768px) {
  .sales_order_main_div {
    display: flex;

    width: 100% !important;

    margin-top: 5px !important;
  }

  .sales_order_window_name {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 10px !important;
    margin-left: 10px !important;
    width: 82% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .sales_order_product_details {
    text-align: start !important;

    padding-left: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sales_order_main_div {
    display: flex;

    width: 100% !important;

    margin-top: 5px !important;
  }

  .sales_order_window_name {
    margin-left: 8px !important;
    width: 39% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .sales_order_product_details {
    text-align: start !important;

    padding-left: 41px !important;

    width: 50% !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .sales_order_main_div {
    display: flex;

    width: 100% !important;

    margin-top: 5px !important;
  }

  .sales_order_window_name {
    margin-top: 10px !important;

    width: 39% !important;

    text-align: start;

    margin-left: -10px !important;
  }

  .sales_order_product_details {
    text-align: start !important;

    padding-left: 41px !important;

    width: 50% !important;
  }

  .sales_order_edit_window_details {
    padding-left: 5px !important;
  }

  .preview_proposal_main_div {
    margin-right: 650px;
  }
}

@media (max-width: 576px) {
  .drawer_input_fraction_buttons {
    margin-left: 4px !important;
  }
}

@media (min-width: 1200px) {
  .sales_order_window_name {
    margin-top: 10px !important;
    width: 41% !important;
    text-align: start;
  }

  .sales_order_main_div {
    display: flex;
    width: 100% !important;
    margin-top: 5px !important;
  }

  .sales_order_product_details {
    text-align: start !important;
    padding-left: 41px !important;
    width: 50% !important;
  }
}

.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ellipsis2 {
  white-space: nowrap !important;
}

/* Json converter & json importer */
.jsonconverter_header {
  background-color: #093236 !important;
}

.download_button {
  width: fit-content !important;
}

.show_appointment_type_div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: 12px;
}

.show_appointment_type_div_1 {
  display: flex;
  align-items: center;
}

.show_appointment_type_font_size {
  font-size: 12px;
}

.appointments_left_margin {
  margin-left: -10px !important;
}

@media (min-width: 768px) {
  .show_appointment_type {
    display: flex;
    align-items: flex-end;
  }

  .show_appointment_type_div {
    margin-bottom: 25px;
    margin-left: 50px;
  }

  .appointments_left_margin {
    margin-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .show_appointment_type_box {
    display: flex;
    justify-content: space-between;
  }

  .show_appointment_type_div {
    width: 300px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .measurement_fraction_main {
    display: flex;
    justify-content: center;
  }

  .drawer_iconbutton {
    color: #ffffff !important;
    min-width: 60px !important;
  }

  .Measurement_fraction_buttons_set2 {
    display: flex;
    justify-content: center;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }

  .drawer_input_fraction_buttons {
    margin-left: 20 px !important;
  }

  .Measurement_fraction_buttons_set1 {
    display: flex;
    margin-right: 60px !important;
  }

  .apply_final_measure_box_cancel {
    position: relative;
    padding-left: 120px !important;
  }

  .preview_proposal_main_div {
    margin-left: 28px !important;
  }
}

@media (max-width: 1024px) and (max-height: 768px) {
  .apply_final_measure_box {
    padding-left: 160px !important;
  }

  .apply_final_measure_box_cancel {
    padding-left: 160px !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }

  .measurement_fractio_type_2 {
    display: flex;
    justify-content: center;
  }

  .sales_order_window_name {
    margin-left: -1px !important;
    width: 41% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .drawer_input_fraction_buttons {
    margin-left: 15px !important;
  }

  .preview_proposal_main_div {
    margin-left: 20px !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }

  .Measurement_fraction_buttons_set1 {
    margin-right: 60px !important;
  }

  .sales_order_window_name {
    margin-left: 8px !important;
    margin-top: 10px !important;
    width: 39% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}

@media (max-width: 1180px) and (max-height: 820px) {
  .drawer_input_fraction_buttons {
    margin-left: 15px !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }

  .Measurement_fraction_buttons_set1 {
    margin-right: 60px !important;
  }

  .drawer_iconbutton {
    color: white !important;
    font-size: 15px !important;
  }

  .measurement_fraction_type_2_div1 {
    align-items: center !important;
  }

  .apply_final_measure_box_cancel {
    padding-left: 160px !important;
  }

  .measurement_fractio_type_2 {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding-bottom: 10px !important;
  }

  .measurement_fraction_main {
    display: flex;
    justify-content: center !important;
    flex-direction: row !important;
  }

  .sales_order_window_name {
    margin-left: -7px !important;
    margin-top: 10px !important;
    width: 41% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}

@media (min-width: 1180px) and (max-height: 820px) {
  .preview_proposal_main_div {
    margin-left: 90px !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }
}

@media (min-width: 375px) and (max-height: 812px) {
  .Measurement_fraction_buttons_set1 {
    display: flex;
    padding-bottom: 10px;
  }

  .drawer_input_buttons_style2 {
    font-size: 18px !important;
  }

  .Measurement_fraction_buttons_set2 {
    display: flex;
    justify-content: center !important;
  }

  .measurement_fraction_type_2_div1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .measurement_fraction_type_2_div2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 390px) and (max-height: 851px) {
  .Measurement_fraction_buttons_set1 {
    display: flex;
    padding-bottom: 10px;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }

  .Measurement_fraction_buttons_set2 {
    display: flex;
    justify-content: center !important;
  }

  .measurement_fraction_type_2_div1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .measurement_fraction_type_2_div2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Measurement_fraction_buttons_set1 {
    display: flex;
    justify-content: center;
  }

  .drawer_input_fraction_buttons {
    margin-left: 20px !important;
  }

  .drawer_iconbutton {
    color: white !important;
    font-size: 15px !important;
  }
}

@media (min-width: 320px) and (max-height: 667px) {
  .drawerGlobalText {
    width: auto !important;
  }

  .apply_final_measure_box_cancel {
    padding-left: 0px !important;
  }

  .Measurement_fraction_buttons_set1 {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }

  .Measurement_fraction_buttons_set2 {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }

  .measurement_fractio_type_2 {
    display: flex !important;
    flex-direction: column !important;
  }

  .measurement_fraction_main {
    display: flex;
    justify-content: center !important;
    flex-direction: column !important;
  }
}

.proposal_price_basis_Main_Box {
  background-color: #f2f2f2 !important;
  border-radius: 5px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 80%;
  box-shadow: 0px 0px 4px #888888, 0px 0px 4px #888888 !important;
}

.proposal_price_Type_Status_width {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;

  padding: 15px 15px !important;
  border-radius: 5px !important;
}

.preview_proposal_main_div {
  display: flex !important;
  justify-content: center !important;
}

/* Table Styles for screens above 1200px */
@media screen and (min-width: 1201px) {
  .windowset-table-dimension {
    width: 800px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .search-table-dimension {
    width: 1000px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }

  .table-dimension-apply-coverings {
    width: 1000px !important;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 25px !important;
    color: #000;
    font-size: 16px !important;
  }
}

dbtools_backbuttton {
  width: 26px !important;
  height: 46px !important;
}

.dbtools_backbuttton {
  width: 26px !important;
  height: 46px !important;
}

.drawer_iconbutton {
  color: #ffffff !important;
}

.textAlignProposalPricingBasis {
  text-align: initial !important;
}

.subAccessoriesAndServicesProposalPricingBasis {
  font-family: 'RobotoLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 20px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
  text-align: initial !important;
}

.table-content_viewPricing_basis {
  font-family: 'RobotoLight !important';
  padding: 0px !important;
  margin-left: 10px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  padding-right: 30px !important;
  padding-left: 30px !important;
  color: #000;
  font-size: 16px !important;
  border-bottom: 0px !important;
}

@media (min-width: 800px) and (max-height: 600px) {
  .measurement_fraction_main {
    display: flex !important;
    flex-direction: row !important;
  }

  .measurement_fractio_type_2 {
    display: flex !important;
    flex-direction: row !important;
  }

  .apply_final_measure_box_cancel {
    position: relative;
    padding-left: 120px !important;
  }

  .drawer_input_fraction_buttons {
    margin-right: 0px !important;
  }

  .Measurement_fraction_buttons_set1 {
    width: fit-content !important;
  }

  .Measurement_fraction_buttons_set2 {
    width: fit-content !important;
  }
}

@media (min-width: 1025px) and (max-height: 834px) {
  .apply_final_measure_box_cancel {
    position: relative;
    padding-left: 170px !important;
  }

  .measurement_fraction_main {
    display: flex;
    justify-content: center;
  }

  .measurement_fractio_type_2 {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 414px) and (min-height: 736px) {
  .measurement_fraction_main {
    display: flex !important;
    flex-direction: column !important;
  }

  .Measurement_fraction_buttons_set1 {
    width: 265px !important;
  }

  .measurement_fractio_type_2 {
    display: flex !important;
    flex-direction: column !important;
  }

  .apply_final_measure_box_cancel {
    position: relative;
    padding-left: 0px !important;
  }

  .Measurement_fraction_buttons_set1 {
    display: flex !important;
    justify-content: center !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 35px !important;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .Measurement_fraction_buttons_set1 {
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 10px !important;
  }

  .measurement_fraction_type_2_div1 {
    display: flex !important;
    justify-content: center !important;
  }

  .measurement_fraction_type_2_div2 {
    display: flex !important;
    justify-content: center !important;
  }

  .drawerGlobalTextDisable {
    width: auto !important;
  }

  .width_and_height {
    text-align: center !important;
    font-size: 20px !important;
  }
}

.AddingsalesOrderWindowName {
  color: rgba(0, 0, 0, 0.87) !important;
}

.removeProposalWindowPromptMainBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.removeProposalWindowPromptMainBox2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1280px) and (min-width: 1050px) {
  .ProposalstepperMainTextSize {
    font-size: 24px !important;
  }

  .ProposalstepperSubTextStyle {
    font-size: 14px !important;
  }
}

@media (max-width: 950px) and (min-width: 768px) {
  .CoveringstepperMainTextSize {
    font-size: 24px !important;
  }

  .CoveringstepperSubTextStyle {
    font-size: 14px !important;
  }
}

@media (max-width: 1050px) {
  .ProposalStepperStack {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
  }
}

.main_container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  width: 70%;
}

.db_search_colors_main {
  display: flex;
  margin-left: 250px;
}

.generated_sql_querry {
  margin-top: 10px !important;
}

.left_alingment_db_color_boxes {
  margin-left: 250px !important;
}

.copy_generate_sql_buttons {
  display: flex;
  margin-left: 250px;
}

.Generate_SQL_Query {
  margin-left: 20px !important;
}

/*--------------------------------------*/

.edit_cusomer_phone_email_popup_alert {
  padding-top: 20;
  padding-left: 26px;
  font-size: 20px;
  font-family: 'RobotoRegular';
}

.edit_lines_addwindow_stepper_maindiv {
  margin-left: 15px;
}

.proposal_stepper_maindiv {
  margin-left: 15px;
}

/* -----------------------calculator_drawer-------------------- */

.drawer_input_display_textfield {
  width: fit-content !important;
  background-color: #fff;
  font-size: x-large;
  border-radius: 5px;
}

.drawer_input_width_height_text {
  font-weight: bold;
  color: white;
}

.width_and_height_header {
  text-align: center !important;
  font-size: x-large !important;
  background-color: #fff !important;
  border-radius: 5px !important;
}

.selected_Colors {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-left: 20px;
  height: 385px;
  width: 240px;
}

.salesOrderMasterRecord_search {
  height: 30px;
  width: 100%;
  border-radius: 10px;
}

.salesOrderMasterRecord_Img {
  width: 20px;
  height: 20px;
  position: relative;
  left: 2px;
}

.sales_order_master_record {
  background-color: #f4f4f4 !important;
  color: #0ca8b0 !important;
  height: 75px;
  font-size: 17px !important;
  font-weight: bold;
}

.Sales_Order_Master_Record_text {
  width: 361px;
}

.pdf_preview {
  background-color: #007bff;
}

.Master_record_pdf_loader {
  padding-top: 20px;
  width: 50px;
}

.Sales_order_master_record_typography {
  font-family: 'RobotoLight !important';
  color: #008593 !important;
  font-size: 18px !important;
  margin-left: 12px !important;
  margin-top: 0px !important;
}

.addtional_note_error {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-left: 15px !important;
  color: red !important;
}

@media (min-width: 1500px) {
  .table_pagination_search_result {
    width: 90%;
  }
}